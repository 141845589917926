export const postPaymentIntent = async (paymentProcessor) => {
  const inFlowApiUrl = import.meta.env.VITE_IN_FLOW_API_URL;
  const token = window.sessionStorage.getItem("session_token");
  const headers = { Authorization: `Bearer ${token}` };
  const res = await fetch(`${inFlowApiUrl}/payment-intent`, {
    method: "POST",
    body: JSON.stringify({ paymentProcessor }),
    headers,
  });
  if (!res.ok) throw new Error(res.status);
  return await res.json();
};
