export const normalizePhoneNumber = (value) => {
  if (!value) {
    return value;
  }
  let numerals = value.replace(/[^\d]/g, "");

  // special cases for deletion
  if (value.length - numerals.length === 2) {
    numerals = numerals.slice(0, -1);
  }

  // decorating input in the National Format
  return numerals.length === 0
    ? ""
    : numerals.length <= 3
    ? `(${numerals.substring(0, 3)}) `
    : numerals.length <= 6
    ? `(${numerals.substring(0, 3)}) ${numerals.substring(3, 6)}`
    : `(${numerals.substring(0, 3)}) ${numerals.substring(
        3,
        6
      )}-${numerals.substring(6)}`;
};
