export const postElavonGooglePay = async (paymentInfo) => {
  const elavonUrl = import.meta.env.VITE_ELAVON_URL;

  const res = await fetch(
    `${elavonUrl}/hosted-payments/service/payment/hpe/ewallet/googlepay/pay`,
    {
      method: "POST",
      body: JSON.stringify(paymentInfo),
    }
  );
  return await res.json();
};
