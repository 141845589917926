import { queryOptions, useQuery } from "@tanstack/react-query";

export const getParkingPass = () => {
  return queryOptions({
    queryKey: ["parkingPass"],
    initialData: {
      passBarcode: "",
    },
  });
};

export const useParkingPass = () => {
  return useQuery({
    ...getParkingPass(),
  });
};
