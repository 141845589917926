import { Button, Modal } from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";

import ArrowBack from "../../assets/Icons/ArrowBack";
import ArrowForward from "../../assets/Icons/ArrowForward";
import CircularProgress from "@mui/material/CircularProgress";
import PathConstants from "../../routes/pathConstants";
import PropTypes from "prop-types";
import { errorLog } from "../../services/errorLog";
import { postPay } from "../../services/pay";
import { useContactInfo } from "../../hooks/contact-info";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const Stripe = (props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(true);

  const data = queryClient.getQueryData(["checkout"]);
  const { transactionDetails } = data ?? { transactionDetails: null };
  const { data: contactInfo } = useContactInfo();
  const dateOnly = new Date().toISOString().split("T")[0];
  const cacheKey = `${contactInfo?.licensePlate}#${dateOnly}#${transactionDetails?.amountDue}`;

  useEffect(() => {
    if (stripe && elements) {
      const element = elements.getElement("payment");
      element.on("ready", () => {
        setIsStripeLoading(false);
      });
    }
  }, [stripe, elements]);

  const handleOnClickBack = () => navigate(-1);

  const onApproval = async (data) => {
    try {
      window.sessionStorage.setItem(cacheKey, JSON.stringify(data));
      const terms = new Date().toISOString();
      // Remove the secret before saving
      data.client_secret = null;
      const paymentObject = {
        stripeResponseData: data,
        termsAndConditionsOK: terms,
        sendTextReceipt: !contactInfo.showEmail,
        sendEmailReceipt: contactInfo.showEmail,
        phoneNumber: contactInfo.phone,
        email: contactInfo.email,
        paymentProcessor: "Stripe",
      };
      const payRes = await postPay(paymentObject);
      queryClient.setQueryData(["pay"], payRes);
      window.sessionStorage.removeItem(cacheKey);
      navigate(PathConstants.CHECKOUT_COMPLETE);
    } catch (error) {
      props.setPaymentIntentError("unknownSystemError");
    } finally {
      setIsLoading(false);
    }
  };

  const resendPayment = () => {
    const cachedReponse = window.sessionStorage.getItem(cacheKey);
    if (cachedReponse) {
      const data = JSON.parse(cachedReponse);
      onApproval(data);
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) return;
    try {
      setIsLoading(true);
      if (resendPayment()) return;
      const { paymentIntent, error } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });
      if (error) {
        props.setPaymentError(
          error.type === "card_error" || error.type === "validation_error"
            ? error.message
            : "An unexpected error occurred."
        );
        setIsLoading(false);
        if (error.type !== "validation_error") {
          try {
            await errorLog(
              `[Payment Processor: Stripe] Error: ${JSON.stringify(error)}`
            );
          } catch (e) {
            console.error("Unable to log error on API", e);
          }
        }
      } else onApproval(paymentIntent);
    } catch (e) {
      try {
        await errorLog(
          `[Payment Processor: Stripe] Unexpected Error: ${JSON.stringify(e)}`
        );
      } catch (e) {
        console.error("Unable to log error on API", e);
      }
      props.setPaymentError("An unexpected error occurred.");
      setIsLoading(false);
    }
  };

  return (
    <div className="pt-4 mx-2 flex flex-col">
      <Modal open={!!isLoading}>
        <CircularProgress
          size={64}
          sx={{
            color: "var(--secondary-color)",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-32px",
            marginLeft: "-32px",
          }}
        />
      </Modal>
      <div>
        <PaymentElement id="payment-element" />
      </div>
      <div>
        <div className="flex border-t border-neutral-light pt-4 mt-8">
          <div className="flex-1 text-left width-50% font-bold text-lg">
            Due Today:
          </div>
          <div className="flex-1 text-right font-bold text-xl">
            <span>
              ${transactionDetails?.amountDue?.toFixed(2).replace(/\.00$/, "")}
            </span>
          </div>
        </div>
        <div className="text-left text-xs">
          All applicable taxes are included.
        </div>
      </div>
      <div className="mt-4 pt-4 border-t border-neutral-light">
        <Button
          size="large"
          variant="contained"
          onClick={() => handleOnClickBack()}
          color="secondary"
          style={{
            fontWeight: 700,
            width: "30%",
            height: "3rem",
            position: "inline-block",
            marginRight: "1rem",
          }}
          startIcon={<ArrowBack fill="var(--neutral-white-color)" />}
        >
          Back
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          disabled={isLoading || isStripeLoading}
          style={{ fontWeight: 700, width: "64%", height: "3rem" }}
          endIcon={
            <ArrowForward
              fill={
                isLoading || isStripeLoading
                  ? "rgba(0, 0, 0, 0.26)"
                  : "var(--secondary-color)"
              }
            />
          }
        >
          Pay
        </Button>
      </div>
    </div>
  );
};

Stripe.propTypes = {
  setPaymentError: PropTypes.func,
  setPaymentIntentError: PropTypes.func,
};

export default Stripe;
