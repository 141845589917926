import { queryOptions, useQuery } from "@tanstack/react-query";

import { postPaymentIntent } from "../services/payment-intent";

export const getPaymentIntent = (
  paymentProcessor,
  successCallback,
  errorCallback
) => {
  return queryOptions({
    queryKey: ["paymentIntent"],
    queryFn: async () => {
      const intentData = await postPaymentIntent(paymentProcessor);

      if (intentData.success === false && errorCallback) {
        errorCallback(intentData.failReason);
        return intentData;
      }

      if (successCallback) successCallback(intentData);
      return intentData;
    },
    retry: false,
  });
};

export const usePaymentIntent = (
  paymentProcessor,
  successCallback,
  errorCallback
) => {
  return useQuery({
    ...getPaymentIntent(paymentProcessor, successCallback, errorCallback),
  });
};
