import { queryOptions, useQuery } from "@tanstack/react-query";

export const getPlate = () => {
  return queryOptions({
    queryKey: ["plate"],
    initialData: {},
  });
};

export const usePlate = () => {
  return useQuery({
    ...getPlate(),
  });
};

export const getLastPlates = () => {
  return queryOptions({
    queryKey: ["lastPlates"],
    queryFn: () =>
      // Look for new storage and old storage location
      JSON.parse(
        localStorage.getItem("lastPlates") ??
          localStorage.getItem("last5Plates")
      ) ?? [],
  });
};

export const useLastPlates = () => {
  return useQuery({
    ...getLastPlates(),
  });
};
