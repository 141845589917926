import { useCallback, useEffect, useState } from "react";

import { Button } from "@mui/material";
import ErrorModal from "../../Layout/ErrorModal";
import PropTypes from "prop-types";
import ScanQrCode from "../../../assets/Icons/ScanQrCode";
import { Scanner } from "@yudiel/react-qr-scanner";

const QRBarcodeScanner = ({ onScanSuccess, onScanFailure }) => {
  const [scanning, setScanning] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const requestCameraPermission = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setDevices(
        await navigator.mediaDevices
          .enumerateDevices()
          .then((devices) =>
            devices.filter((device) => device.kind === "videoinput")
          )
      );
      stream.getTracks().forEach((track) => track.stop());
      setHasPermission(true);
    } catch (error) {
      console.error("Error requesting camera permission:", error);
      setErrorMessage("cameraPermissionsDenied");
      setShowErrorModal(true);
      onScanFailure(new Error("Camera permission denied"));
      setScanning(false);
    }
  }, [onScanFailure]);

  const selectDevice = useCallback(() => {
    if (devices.length > 0) {
      const cameraPriority = ["Ultra Wide", "Wide", "Back Camera"];

      let selectedDevice = null;

      // Filter out triple and dual cameras
      const filteredDevices = devices.filter(
        (d) =>
          !d.label.toLowerCase().includes("triple") &&
          !d.label.toLowerCase().includes("dual") &&
          !d.label.toLowerCase().includes("desk view")
      );

      // loop through the camera priority list and select the first camera that matches
      for (const priority of cameraPriority) {
        selectedDevice = filteredDevices.find((d) =>
          d.label.includes(priority)
        );
        if (selectedDevice) break;
      }

      // If no prioritized camera is found, default to the last device in the filtered list
      if (!selectedDevice && filteredDevices.length > 0) {
        selectedDevice = filteredDevices[filteredDevices.length - 1];
      }

      if (selectedDevice) {
        // alert("Selected device:", selectedDevice.label);
        setSelectedDevice(selectedDevice);
      } else {
        // alert("No suitable camera found");
        console.log("No suitable camera found");
        setErrorMessage("suitableCameraNotFound");
        onScanFailure(new Error("No suitable camera found"));
        setScanning(false);
      }
    }
  }, [devices, onScanFailure]);

  useEffect(() => {
    const initializeCamera = async () => {
      if (!hasPermission) {
        // alert("Requesting permission");
        await requestCameraPermission();
      }

      if (hasPermission) {
        // alert("Has permission");
        // alert("Selecting device");
        selectDevice();
      }
    };

    if (scanning) {
      initializeCamera();
    } else {
      setSelectedDevice({});
    }
  }, [scanning, hasPermission, requestCameraPermission, selectDevice]);

  const handleScan = useCallback(
    (data) => {
      console.log("Scanned data:", data);
      const found = data.find(
        (item) => item.rawValue.includes("TBN") || item.format === "code_128"
      );
      if (found) {
        if (found.format === "code_128") {
          onScanSuccess(found.rawValue);
        } else {
          const tbn = found.rawValue
            .split("|")
            .find((item) => item.includes("TBN"))
            .replace("TBN=", "");
          onScanSuccess(tbn);
        }
        setScanning(false);
      }
    },
    [onScanSuccess]
  );

  const startScanning = () => {
    setScanning(true);
  };

  const stopScanning = () => {
    setScanning(false);
    setSelectedDevice({});
  };

  return (
    <div className="qr-scanner-container">
      <ErrorModal
        open={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        failReason={errorMessage}
      />
      {!scanning ? (
        <Button
          size="large"
          variant="contained"
          onClick={startScanning}
          color="primary"
          className="w-full h-12 mt-4 font-bold"
          startIcon={<ScanQrCode />}
        >
          Scan QR or Barcode
        </Button>
      ) : !hasPermission ? (
        <div className="text-secondary leading-4 text-sm mx-2">
          Requesting camera permissions...
        </div>
      ) : !selectedDevice.deviceId ? (
        <div className="text-secondary leading-4 text-sm mx-2">
          Initializing camera...
        </div>
      ) : (
        <Scanner
          key={selectedDevice.deviceId}
          onScan={handleScan}
          onError={(error) => {
            console.error("Scanner error:", error);
            onScanFailure(error);
            stopScanning();
          }}
          constraints={{
            advanced: [
              {
                facingMode: "environment",
                deviceId: selectedDevice.deviceId,
              },
            ],
            // focusMode: "continuous",
          }}
          styles={{
            container: {
              width: "100%",
              height: "0", // hack to make the video not shift the layout
              paddingBottom: "100%", // hack to make the video not shift the layout
              overflow: "hidden",
            },
            video: {
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }, // hack to make the video not shift the layout
          }}
          formats={["qr_code", "pdf417", "code_128"]}
          torch={true}
        />
      )}
    </div>
  );
};

QRBarcodeScanner.propTypes = {
  onScanSuccess: PropTypes.func.isRequired,
  onScanFailure: PropTypes.func.isRequired,
};

export default QRBarcodeScanner;
