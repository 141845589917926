import { queryOptions, useQuery } from "@tanstack/react-query";
import { postRates } from "../services/rates";

export const getRate = (onSuccessRapidCheckout) => {
  return queryOptions({
    queryKey: ["rate"],
    queryFn: async () => {
      const res = await postRates();
      if (res.useRapidCheckout && onSuccessRapidCheckout) {
        onSuccessRapidCheckout();
      }
      return res;
    },

    initialData: {
      rates: [],
    },
    retry: false,
  });
};

export const useRate = (onSuccessRapidCheckout) => {
  return useQuery({
    ...getRate(onSuccessRapidCheckout),
  });
};
