import React from "react";

const Receipt = (props) => {
  const { fill } = props;
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 4C1.5 2.61929 2.61929 1.5 4 1.5H7V0.5H4C2.067 0.5 0.5 2.067 0.5 4V7H1.5V4ZM13 1.5H16C17.3807 1.5 18.5 2.61929 18.5 4V7H19.5V4C19.5 2.067 17.933 0.5 16 0.5H13V1.5ZM1.5 16V13H0.5V16C0.5 17.933 2.067 19.5 4 19.5H7V18.5H4C2.61929 18.5 1.5 17.3807 1.5 16ZM18.5 16C18.5 17.3807 17.3807 18.5 16 18.5H13V19.5H16C17.933 19.5 19.5 17.933 19.5 16V13H18.5V16ZM6 7V6H7V7H6ZM4 5C4 4.44772 4.44772 4 5 4H8C8.55228 4 9 4.44772 9 5V8C9 8.55228 8.55228 9 8 9H5C4.44772 9 4 8.55228 4 8V5ZM13 6V7H14V6H13ZM12 4C11.4477 4 11 4.44772 11 5V8C11 8.55228 11.4477 9 12 9H15C15.5523 9 16 8.55228 16 8V5C16 4.44772 15.5523 4 15 4H12ZM6 14V13H7V14H6ZM4 12C4 11.4477 4.44772 11 5 11H8C8.55228 11 9 11.4477 9 12V15C9 15.5523 8.55228 16 8 16H5C4.44772 16 4 15.5523 4 15V12ZM11.5 14C11.2239 14 11 14.2239 11 14.5V15.5C11 15.7761 11.2239 16 11.5 16H12.5C12.7761 16 13 15.7761 13 15.5V14.5C13 14.2239 12.7761 14 12.5 14H11.5ZM14 11.5C14 11.2239 14.2239 11 14.5 11H15.5C15.7761 11 16 11.2239 16 11.5V12.5C16 12.7761 15.7761 13 15.5 13H14.5C14.2239 13 14 12.7761 14 12.5V11.5Z"
        fill="#12284A"
      />
    </svg>
  );
};

export default Receipt;
