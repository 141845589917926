import React from "react";

const CheckboxChecked = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="28" rx="6" fill="#12284A" />
      <path
        d="M12.4662 20.25L6.81662 15.0311C6.41308 14.6296 6.41308 14.0274 6.71574 13.626C7.11927 13.2245 7.72458 13.2245 8.12812 13.5256L12.1635 17.2391L19.7298 8.10593C20.1334 7.70447 20.7387 7.60411 21.1422 8.00557C21.5458 8.40702 21.6466 9.00921 21.2431 9.41067L12.4662 20.25Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckboxChecked;
