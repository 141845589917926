import React from "react";

const ArrowBack = (props) => {
  const { fill } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2929 18.2071C10.6834 18.5976 11.3166 18.5976 11.7071 18.2071C12.0976 17.8166 12.0976 17.1834 11.7071 16.7929L7.91421 13L18.5 13C19.0523 13 19.5 12.5523 19.5 12C19.5 11.4477 19.0523 11 18.5 11L7.91421 11L11.7071 7.20711C12.0976 6.81658 12.0976 6.18342 11.7071 5.79289C11.3166 5.40237 10.6834 5.40237 10.2929 5.79289L4.79352 11.2923L4.79289 11.2929C4.79072 11.2951 4.78855 11.2973 4.7864 11.2995C4.59622 11.4931 4.50076 11.7449 4.5 11.997M4.7864 12.7005C4.69374 12.6062 4.62357 12.498 4.57588 12.3828C4.62536 12.5021 4.69729 12.6098 4.7864 12.7005ZM10.2929 18.2071L4.79352 12.7077L10.2929 18.2071Z"
        fill={fill ? fill : "#12284A"}
      />
    </svg>
  );
};

export default ArrowBack;
