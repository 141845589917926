import React from "react";

const ArrowForward = (props) => {
  const { fill } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7071 5.79289C13.3166 5.40237 12.6834 5.40237 12.2929 5.79289C11.9024 6.18342 11.9024 6.81658 12.2929 7.20711L16.0858 11H5.5C4.94772 11 4.5 11.4477 4.5 12C4.5 12.5523 4.94772 13 5.5 13H16.0858L12.2929 16.7929C11.9024 17.1834 11.9024 17.8166 12.2929 18.2071C12.6834 18.5976 13.3166 18.5976 13.7071 18.2071L19.2065 12.7077L19.2071 12.7071C19.2093 12.7049 19.2115 12.7027 19.2136 12.7005C19.4038 12.5069 19.4992 12.2551 19.5 12.003M19.2136 11.2995C19.3063 11.3938 19.3764 11.502 19.4241 11.6172C19.3746 11.4979 19.3027 11.3902 19.2136 11.2995ZM13.7071 5.79289L19.2065 11.2923L13.7071 5.79289Z"
        fill={fill ? fill : "#12284A"}
      />
    </svg>
  );
};

export default ArrowForward;
