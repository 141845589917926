import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import PrivacyAndTerms from "../PrivacyAndTerms";
import BackArrow from "../../../assets/Icons/ArrowBack";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../../../assets/Icons/Spinner";
import Modal from "../Modal";
import Alert from "../../../assets/Icons/Alert";
import PropTypes from "prop-types";
import TapNTailgateLogo from "../../../assets/Logos/TapNTailgateLogo";

const PageLayout = (props) => {
  const {
    children,
    title,
    subTitle,
    withCancel,
    cancelRoute,
    isLoading,
    showHelp,
    onCloseHelp,
    networkError,
    networkErrorMessage,
    onCloseNetworkError,
    logoReplacement,
    withoutTerms,
    hideHelpButton,
  } = props;

  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ["welcome"],
  });

  const handleOnClickCancel = () => {
    if (cancelRoute) {
      navigate(cancelRoute);
    } else {
      navigate(-1);
    }
  };

  // TODO Uncomment real logo when we get it set up correctly

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col w-full min-h-screen">
        {isLoading ? (
          <div
            className="pt-2 flex flex-col justify-center items-center"
            style={{ height: "calc(100vh - 130px)" }}
          >
            <div className="h-2">
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <div className="pb-4 header-shadow relative">
              {logoReplacement ? (
                <h1 className="mt-8 mb-2">{logoReplacement}</h1>
              ) : (
                <>
                  <div
                    style={{
                      maxWidth: "4.375rem",
                      margin: "0 auto",
                      paddingTop: "1rem",
                      /* cursor: "pointer", */
                    }}
                  >
                    {data?.skin?.logoURL ? (
                      <>
                        {loaded ? null : (
                          <div
                            style={{
                              background: "white",
                              height: "4.375rem",
                              width: "4.375rem",
                            }}
                          />
                        )}
                        <img
                          src={data?.skin?.logoURL}
                          style={loaded ? {} : { display: "none" }}
                          onLoad={() => setLoaded(true)}
                        />
                      </>
                    ) : (
                      <TapNTailgateLogo />
                    )}
                  </div>
                  {withCancel && (
                    <Button
                      size="large"
                      onClick={handleOnClickCancel}
                      color="secondary"
                      style={{
                        position: "absolute",
                        left: ".5rem",
                        top: "2rem",
                        fontWeight: 700,
                        fontSize: "16px",
                      }}
                    >
                      <BackArrow /> <span className="ml-2">Back</span>
                    </Button>
                  )}
                </>
              )}
            </div>
            <div className="flex flex-col h-full grow justify-start">
              <div className="mx-4 mt-8">
                <h1 className="font-bold text-2xl">{title}</h1>
              </div>
              <div className="mx-4 mt-1">
                <h2 style={{ fontWeight: 700, color: "#4e5663" }}>
                  {subTitle}
                </h2>
              </div>
              <div className="mx-auto w-full" style={{ maxWidth: "460px" }}>
                <div className="px-4 mt-8 w-full">{children}</div>
              </div>
            </div>
          </>
        )}
        <PrivacyAndTerms
          showHelp={showHelp}
          onCloseHelp={onCloseHelp}
          withoutTerms={withoutTerms}
          welcomeData={data}
          hideHelpButton={hideHelpButton}
          isLoading={isLoading}
        ></PrivacyAndTerms>
      </div>
      <Modal open={networkError} onClose={onCloseNetworkError}>
        <div>
          <span className="mx-auto">
            <Alert />
            <div className="text-black">
              <div className="text-center mt-4 font-bold text-lg">Warning</div>
              <div className="text-center mt-4"> {networkErrorMessage}</div>
              <div
                className="mt-4"
                style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}
              >
                <Divider />
              </div>
              <div>
                <div className="mt-4 flex justify-end">
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={onCloseNetworkError}
                    color="primary"
                    style={{
                      fontWeight: 700,
                      height: "2.5rem",
                      width: "45%",
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </span>
        </div>
      </Modal>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  withCancel: PropTypes.bool,
  cancelRoute: PropTypes.string,
  isLoading: PropTypes.bool,
  showHelp: PropTypes.bool,
  onCloseHelp: PropTypes.func,
  networkError: PropTypes.bool,
  networkErrorMessage: PropTypes.string,
  onCloseNetworkError: PropTypes.func,
  logoReplacement: PropTypes.any,
  withoutTerms: PropTypes.bool,
  hideHelpButton: PropTypes.bool,
};

export default PageLayout;
