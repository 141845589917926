import { CircularProgress } from "@mui/material";
import React from "react";

const Spinner = () => {
  return (
    <React.Fragment>
      <CircularProgress
        sx={{ "svg circle": { stroke: "var(--secondary-color)" } }}
      />
    </React.Fragment>
  );
};

export default Spinner;
