import React from "react";

const Visa = () => {
  return (
    <svg
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.499969 1.75C0.499969 0.783503 1.314 0 2.31815 0H18.6818C19.6859 0 20.5 0.783502 20.5 1.75V12.25C20.5 13.2165 19.6859 14 18.6818 14H2.31815C1.314 14 0.499969 13.2165 0.499969 12.25V1.75Z"
        fill="#172B85"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.86376 9.38879H5.60494L4.66098 5.87234C4.61618 5.71059 4.52104 5.56758 4.38111 5.50018C4.03188 5.33081 3.64705 5.19601 3.22724 5.12803V4.99264H5.2551C5.53497 4.99264 5.74488 5.19601 5.77986 5.4322L6.26964 7.96873L7.52785 4.99264H8.75168L6.86376 9.38879ZM9.45127 9.38879H8.26242L9.24136 4.99264H10.4302L9.45127 9.38879ZM11.9684 6.2105C12.0034 5.97373 12.2133 5.83834 12.4582 5.83834C12.843 5.80435 13.2622 5.87234 13.6121 6.04113L13.822 5.09461C13.4721 4.95923 13.0873 4.89125 12.7381 4.89125C11.5842 4.89125 10.7446 5.50018 10.7446 6.3453C10.7446 6.98822 11.3393 7.3258 11.7591 7.52917C12.2133 7.73195 12.3882 7.86733 12.3532 8.07012C12.3532 8.37429 12.0034 8.50967 11.6542 8.50967C11.2344 8.50967 10.8145 8.40828 10.4303 8.23891L10.2204 9.186C10.6402 9.35479 11.0944 9.42278 11.5142 9.42278C12.808 9.45618 13.6121 8.84784 13.6121 7.93473C13.6121 6.78485 11.9684 6.71746 11.9684 6.2105ZM17.7727 9.38879L16.8287 4.99264H15.8148C15.6049 4.99264 15.395 5.12803 15.325 5.33081L13.577 9.38879H14.8009L15.0451 8.74645H16.5488L16.6888 9.38879H17.7727ZM15.9897 6.17652L16.339 7.83335H15.36L15.9897 6.17652Z"
        fill="#FEFFFF"
      />
    </svg>
  );
};

export default Visa;
