export const postWelcome = async (qr) => {
  const inFlowApiUrl = import.meta.env.VITE_IN_FLOW_API_URL;

  const res = await fetch(`${inFlowApiUrl}/welcome`, {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify({
      qr,
    }),
  });
  if (!res.ok) throw new Error(res.status);
  return await res.json();
};
