export const postPlate = async (plate) => {
  storePlateInLocalStorage(plate);

  const inFlowApiUrl = import.meta.env.VITE_IN_FLOW_API_URL;
  const token = window.sessionStorage.getItem("session_token");
  const headers = { Authorization: `Bearer ${token}` };

  const res = await fetch(`${inFlowApiUrl}/plate`, {
    method: "POST",
    body: JSON.stringify({ plate }),
    headers,
  });
  return await res.json();
};

export const postPlateUpdate = async (receiptID, newPlate, oldPlate) => {
  const inFlowApiUrl = import.meta.env.VITE_IN_FLOW_API_URL;
  const token = window.sessionStorage.getItem("session_token");
  const headers = { Authorization: `Bearer ${token}` };
  const res = await fetch(`${inFlowApiUrl}/plate-update`, {
    method: "POST",
    body: JSON.stringify({ receiptID, newPlate }),
    headers,
  });
  const resBody = await res.json();
  if (!resBody.success) throw new Error(resBody.failReason);
  await removePlateIFromLocalStorage(oldPlate);
  await storePlateInLocalStorage(newPlate);
  return resBody;
};

export const storePlateInLocalStorage = async (plate) => {
  const lsstring = localStorage.getItem("lastPlates");
  let storedPlates;

  if (lsstring?.length > 0) storedPlates = JSON.parse(lsstring);
  else storedPlates = [];

  const index = storedPlates.indexOf(plate);
  if (index > -1) storedPlates.splice(index, 1);

  storedPlates.unshift(plate);
  // Only store the 3 that were last used
  localStorage.setItem("lastPlates", JSON.stringify(storedPlates.splice(0, 3)));
};

export const removePlateIFromLocalStorage = async (plate) => {
  const lsstring = localStorage.getItem("lastPlates");
  let storedPlates;

  if (lsstring?.length > 0) storedPlates = JSON.parse(lsstring);
  else storedPlates = [];

  const index = storedPlates.indexOf(plate);
  if (index > -1) storedPlates.splice(index, 1);

  // Only store the 3 that were last used
  localStorage.setItem("lastPlates", JSON.stringify(storedPlates.splice(0, 3)));
};
