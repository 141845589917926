import { queryOptions, useQuery } from "@tanstack/react-query";
import { postWelcome } from "../services/welcome";

export const getWelcome = (
  qr,
  networkErrorCallback,
  missingQRCallback,
  nullQRCallback
) => {
  if (!qr && missingQRCallback) missingQRCallback();
  if (qr && qr === "null" && nullQRCallback) nullQRCallback();
  return queryOptions({
    queryKey: ["welcome"],
    queryFn: async () => {
      const res = await postWelcome(qr);
      if (!res && networkErrorCallback) {
        networkErrorCallback();
      }
      return res;
    },
    retry: 1,
  });
};

export const useWelcome = (
  qr,
  errorCallback,
  missingQRCallback,
  nullQRCallback
) => {
  return useQuery({
    ...getWelcome(qr, errorCallback, missingQRCallback, nullQRCallback),
  });
};
