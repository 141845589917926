import React from "react";

const TicketIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 12C17 13.6569 18.3431 15 20 15V16C20 16.5523 19.5523 17 19 17H15L15 7L19 7C19.5523 7 20 7.44772 20 8V9C18.3431 9 17 10.3431 17 12ZM13 7L5 7C4.44808 7 4.0006 7.44712 4 7.9989L4 9C5.65685 9 7 10.3431 7 12C7 13.6569 5.65685 15 4 15L4 16.0011C4.0006 16.5529 4.44808 17 5 17L13 17L13 7ZM20 13C21.1046 13 22 13.8954 22 15V16C22 17.6569 20.6569 19 19 19L5 19C3.34314 19 2 17.6569 2 16V15C2 13.8954 2.89543 13 4 13C4.55229 13 5 12.5523 5 12C5 11.4477 4.55229 11 4 11C2.89543 11 2 10.1046 2 9V8C2 6.34315 3.34315 5 5 5H19C20.6569 5 22 6.34315 22 8V9C22 10.1046 21.1046 11 20 11C19.4477 11 19 11.4477 19 12C19 12.5523 19.4477 13 20 13Z"
        fill="#12284A"
      />
    </svg>
  );
};

export default TicketIcon;
