import { Box, Modal as ModalMaterial } from "@mui/material";
import X from "../../../assets/Icons/X";
import PropTypes from "prop-types";

const Modal = (props) => {
  const { children, open = false, onClose, title, withClose } = props;

  return (
    <ModalMaterial open={!!open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          backgroundColor: "var(--neutral-white-color)",
          maxWidth: "428px",
          borderRadius: "1rem",
          p: "1.5rem",
        }}
      >
        {title && (
          <div
            className="float-left cursor-pointer"
            style={{
              marginTop: "-.5rem",
              marginLeft: "-.5rem",
              marginRight: "-2rem",
              position: "relative",
            }}
          >
            <span className="text-lg font-bold">{title}</span>
          </div>
        )}
        {withClose && (
          <div
            onClick={onClose}
            className="float-right cursor-pointer"
            style={{
              marginTop: "-.5rem",
              marginRight: "-.5rem",
              marginLeft: "-2rem",
              position: "relative",
            }}
          >
            <X />
          </div>
        )}
        <div>{children}</div>
      </Box>
    </ModalMaterial>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  withClose: PropTypes.bool,
};

export default Modal;
