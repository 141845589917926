import PageLayout from "../../components/Layout/PageLayout";

const Error = () => {
  const title = "Something Went Wrong";
  return (
    <PageLayout title={title}>
      <div className="font-bold">
        We&apos;re sorry, but an unexpected error has occurred. Please try again
        later or contact support if the issue persists.
      </div>
    </PageLayout>
  );
};

export default Error;
