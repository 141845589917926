import PageLayout from "../../components/Layout/PageLayout";

const TermsOfUse = () => {
  return (
    <PageLayout title={`Terms of Use`} withCancel withoutTerms>
      <div className="text-left">
        <p>Last Modified: March 21, 2024</p>
        <p className="my-2">
          <b>
            <u>Acceptance of the Terms of Use </u>
          </b>
        </p>
        <p>
          These terms of use are entered into by and between You (“<b>You</b>”
          or “<b>Your</b>”) and IPC Technology Group, LLC and Interstate
          Parking, LLC (collective, “<b>Company</b>,” “<b>we</b>,” or “<b>us</b>
          ”). The following terms and conditions, together with any documents
          they expressly incorporate by reference (collectively, “
          <b>Terms of Use</b>”), govern Your access to and use of our websites,
          including any content, functionality, and services (the “
          <b>Website</b>”), whether as a guest or a registered user.
        </p>
        <p>
          Please read the Terms of Use carefully before You start to use the
          Website AND SERVICESAS THEY CONTAIN AN AGREEMENT TO ARBITRATE, CLASS
          ACTION WAIVER, AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL
          RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE REQUIRES
          (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO
          BINDING AND FINAL ARBITRATION, AND FURTHER (1) YOU WILL ONLY BE
          PERMITTED TO PURSUE CLAIMS AGAINST THE COMPANY ON AN INDIVIDUAL BASIS,
          NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE
          ACTION OR PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF
          (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN
          INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU
          HAVE AGAINST US RESOLVED BY A JURY OR IN A COURT OF LAW. YOU CAN OPT
          OUT OF THIS AGREEMENT TO ARBITRATE BY CONTACTING
          LEGAL@INTERSTATEPARKING.COM WITHIN 30 DAYS AFTER FIRST ACCEPTING THESE
          TERMS OF SERVICE AND STATING YOUR FIRST AND LAST NAME AND THAT YOU
          DECLINE THE TERMS TO ARBITRATE.{" "}
          <b>
            By using the Website and Services, You accept and agree to be bound
            and abide by these Terms of Use and our Privacy Policy,
          </b>{" "}
          (accessible at{" "}
          <a href="https://tapntailgate.app/privacy-policy">
            https://tapntailgate.app/privacy-policy
          </a>
          ),<b>incorporated herein by reference.</b> If You do not want to agree
          to these Terms of Use or the Privacy Policy, You must not access or
          use the Website or Services.
        </p>
        <p>
          By using this Website and Services, You represent and warrant that You
          are of legal age to form a binding contract with the Company and meet
          all of the foregoing eligibility requirements. Any use or access by
          anyone under age 13 is prohibited. If You do not meet all of these
          requirements, You must not access or use the Website. If You fail to
          comply with these Terms of Use, Your permission to use the Website
          automatically terminates.
        </p>
        <p className="my-2">
          <b>
            <u>Changes to the Terms of Use</u>
          </b>
        </p>
        <p>
          We may revise and update these Terms of Use from time to time in our
          sole discretion. All changes are effective immediately when we post
          them, and apply to all access to and use of the Website thereafter.
        </p>
        <p>
          Your continued use of the Website following the posting of revised
          Terms of Use means that You accept and agree to the changes. You are
          expected to check this page from time to time You access this Website
          so You are aware of any changes, as they are binding on You.
        </p>{" "}
        <p className="my-2">
          <b>
            <u>Services</u>
          </b>
        </p>
        <p>
          {" "}
          The services provided by the Company allow You to book a parking spot
          at selected parking facilities across various cities using an
          application or other technology to book and pay for use of the parking
          spot (“Services”). You can reserve a parking spot by scanning a QR
          code associated with a particular parking facility that will direct
          You to our Website to enter reservation details and payment
          information.
        </p>
        <p className="my-2">
          <b>
            <u>Payments and Fees</u>
          </b>
        </p>
        <p>
          You understand that use of the Services may result in charges to You
          for the Services You receive and for use of the application
          (&quot;Charges&quot;). We will receive and/or enable Your payment of
          the applicable Charges for services obtained through Your use of the
          Services. Charges will be inclusive of applicable taxes where required
          by law. Charges may include other applicable fees, processing fees, or
          a convenience fee for use of the application in relation to the
          Services. The convenience fee will be assessed on a per-transaction
          basis for use of the application and disclosed to You at the time of
          the transaction.
        </p>
        <p>
          All Charges and payments will be enabled by the Company using the
          preferred payment provided by You, after which You will receive a
          receipt by text and/or email. You represent and warrant that You are
          authorized to use the payment method provided by You and that the
          payment information is accurate. If the payment method provided by You
          is determined to be expired, invalid or otherwise not able to be
          charged, Your request to use the Services will be declined and You
          will be notified by text and/or email. Charges paid by You are final
          and non-refundable, unless otherwise determined by the Company.
        </p>
        <p>
          You understand that should your Services expire while You are still
          using a parking spot, all penalties and costs for parking without
          payment applicable to the parking location will apply. This includes
          but is not limited to parking violations and related late fees for
          non-payment. As between You and the Company, the Company reserves the
          right to establish, remove and/or revise Charges for any or all
          services obtained through the use of the Services at any time in our
          sole discretion. We will use reasonable efforts to inform You of
          Charges that may apply, provided that You will be responsible for
          Charges incurred by You regardless of Your awareness of such Charges
          or the amounts thereof. We may from time to time provide certain users
          with promotional offers and discounts that may result in different
          amounts charged for the same or similar services or goods obtained
          through the use of the Services, and You agree that such promotional
          offers and discounts, unless also made available to You, shall have no
          bearing on Your use of the Services or the Charges applied to You.
        </p>
        <p>
          In certain cases, with respect to third party providers, Charges You
          incur will be owed directly to third party providers, and the Company
          will collect payment of those charges from You, on the third party
          provider&apos;s behalf as their limited payment collection agent, and
          payment of the Charges shall be considered the same as payment made
          directly by You to the third party provider.
        </p>
        <p className="my-2">
          <b>
            <u>Communications</u>
          </b>
        </p>
        <p>
          By accepting these Terms of Use, You expressly consent to the Company,
          either directly or through third parties acting on the Company’s
          behalf, to communicate with You via your mobile device and by email.
          This includes sending informational texts such as messages that your
          parking session is about to expire, marketing, and promotional
          messages, including SMS, MMS, or text messages and calls made using an
          automatic telephone dialing system or by pre-recorded or artificial
          message related to the Services. Such messages may be made to any
          telephone number that You provide to the Company. Accepting this
          consent is not required to obtain any good or service. You also
          consent to the Company communicating by e-mail to the email address
          provided by You in relation to the use of the Website or Services.
        </p>
        <p className="my-2">
          <b>
            <i>Short Code Texting.</i>
          </b>
          In addition to the texting described above, the Company also offers
          the ability to pay by text using “short code” texting (“
          <b>SMS Service</b>”) where You may text the Company using a code
          posted at one of the Company’s parking facilities. By texting a
          keyword to 94201, You consent to receive one or more automated text
          messages at the phone number from which You texted. Message and Data
          Rates may apply.
        </p>
        <p>
          The Company will not be liable for any delays in the receipt of any
          SMS messages as delivery is subject to effective transmission from
          Your mobile service operator. THE SMS SERVICE IS PROVIDED ON AN “AS
          IS” BASIS, AND WE MAKE NO WARRANTY, EXPRESS OR IMPLIED, AND ALL
          WARRANTIES, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY AND
          FITNESS FOR PARTICULAR PURPOSE, ARE HEREBY EXPRESSLY DISCLAIMED.
        </p>
        <p>
          Data obtained from You in connection with this SMS Service may include
          Your cell phone number, Your carrier’s name, and the date, time and
          content of your messages, as well as other information that you
          provide. We may use this information to contact you and to provide the
          services you request from us. By subscribing, You consent to receive
          up to four text messages per month utilizing the SMS Service. By
          subscribing or otherwise using the service, you acknowledge and agree
          that we will have the right to change and/or terminate the service at
          any time, with or without cause and/or advance notice.
        </p>
        <p>
          To cancel your SMS subscriptions, text STOP to 94201 in reply to a
          text message You receive. You may receive a subsequent message
          confirming your opt-out request.
        </p>
        <p>
          T-Mobile® is not liable for delayed or undelivered messages. United
          States Participating Carriers Include: AT&amp;T®, T-Mobile®, Verizon
          Wireless®, Sprint®, Boost®, U.S. Cellular®, MetroPCS®, InterOp®,
          Cellcom®, C Spire Wireless®, Cricket®, Virgin Mobile® and others.
        </p>
        <p>
          The SMS Service is only available to residents of the United States.
          You understand and acknowledge that You may not sign up for, access,
          or attempt to access or use the SMS Service from countries outside of
          the U.S. You agree to abide by U.S. and other applicable export
          control laws and not to transfer, by electronic transmission or
          otherwise, any content or software subject to restrictions under such
          laws to a national destination or person prohibited under such laws.
        </p>
        <p>
          For questions or support relative to the SMS Service, You can contact
          us at info@interstateparking.com at any time, from Your mobile phone,
          send a text message with the word “HELP” to 94201. You may also call
          the Company’s support line: (414) 431-6555.
        </p>
        <p className="my-2">
          <b>
            <u>Accessing the Website and Account Security</u>
          </b>
        </p>
        <p>
          We reserve the right to withdraw or amend this Website, and any
          service or material we provide on the Website, in our sole discretion
          without notice. We will not be liable if, for any reason, all or any
          part of the Website is unavailable at any time or for any period. From
          time to time, we may restrict access to some parts of the Website, or
          the entire Website, to users, including registered users.
        </p>
        <div>
          You are responsible for both:
          <ul>
            <li>
              Making all arrangements necessary for You to have access to the
              Website.
            </li>
            <li>
              Ensuring that all persons who access the Website through Your
              internet connection or systems are aware of these Terms of Use and
              comply with them.
            </li>
          </ul>
          To access the Website or some of the resources it offers, You may be
          asked to provide certain registration details or other information. It
          is a condition of Your use of the Website that all the information You
          provide on the Website is correct, current, and complete. You agree
          that all information You provide to register with this Website or
          otherwise, including, but not limited to, through the use of any
          interactive features on the Website, is governed by our Privacy Policy
          (accessible at{" "}
          <a href="https://tapntailgate.app/privacy-policy">
            https://tapntailgate.app/privacy-policy
          </a>
          ), and You consent to all actions we take with respect to Your
          information consistent with our Privacy Policy.
        </div>
        <p>
          If You choose, or are provided with, a user name, password, or any
          other piece of information as part of our security procedures, You
          must treat such information as confidential, and You must not disclose
          it to any other person or entity. You also acknowledge that Your
          account is personal to You and agree not to provide any other person
          with access to this Website or portions of it using Your user name,
          password, or other security information. You agree to notify us
          immediately of any unauthorized access to or use of Your user name or
          password or any other breach of security. You also agree to ensure
          that You exit from Your account at the end of each session. You should
          use particular caution when accessing Your account from a public or
          shared computer so that others are not able to view or record Your
          password or other personal information.
        </p>
        <p>
          We have the right to disable any user name, password, or other
          identifier, whether chosen by You or provided by us, at any time in
          our sole discretion for any or no reason, including if, in our
          opinion, You have violated any provision of these Terms of Use.
        </p>
        <p className="my-2">
          <b>
            <u>Intellectual Property Rights</u>
          </b>
        </p>
        <p>
          The Website and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and
          arrangement thereof) are owned by the Company, its licensors, or other
          providers of such material and are protected by United States and
          international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws.
        </p>
        <div>
          These Terms of Use permit You to use the Website for Your personal,
          non-commercial use only. You must not reproduce, distribute, modify,
          create derivative works of, publicly display, publicly perform,
          republish, download, store, or transmit any of the material on our
          Website, except as follows:
          <ul>
            <li>
              Your computer may temporarily store copies of such materials in
              RAM incidental to Your accessing and viewing those materials.
            </li>
            <li>
              You may store files that are automatically cached by Your Web
              browser for display enhancement purposes.
            </li>
            <li>
              You may print or download one copy of a reasonable number of pages
              of the Website for Your own personal, non-commercial use and not
              for further reproduction, publication, or distribution.
            </li>
            <li>
              If we provide desktop, mobile, or other applications for download,
              You may download a single copy to Your computer or mobile device
              solely for Your own personal, non-commercial use, provided You
              agree to be bound by our end user license agreement for such
              applications.
            </li>
          </ul>
          You must not:
          <ul>
            <li>Modify copies of any materials from this site.</li>
            <li>
              Use any illustrations, photographs, video or audio sequences, or
              any graphics separately from the accompanying text.
            </li>
            <li>
              Delete or alter any copyright, trademark, or other proprietary
              rights notices from copies of materials from this site.
            </li>
          </ul>
        </div>
        <p>
          If You print, copy, modify, download, or otherwise use or provide any
          other person with access to any part of the Website in breach of the
          Terms of Use, Your right to use the Website will stop immediately and
          You must, at our option, return or destroy any copies of the materials
          You have made. No right, title, or interest in or to the Website or
          any content on the Website is transferred to You, and all rights not
          expressly granted are reserved by the Company. Any use of the Website
          not expressly permitted by these Terms of Use is a breach of these
          Terms of Use and may violate copyright, trademark, and other laws.{" "}
        </p>
        <p className="my-2">
          <b>
            <u>Trademarks</u>
          </b>
        </p>
        <p>
          The Company name, the Company logo, and all related names, logos,
          product and service names, designs, and slogans are trademarks of the
          Company or its affiliates or licensors. You must not use such marks
          without the prior written permission of the Company. All other names,
          logos, product and service names, designs, and slogans on this Website
          are the trademarks of their respective owners.
        </p>
        <p className="my-2">
          <b>
            <u>Prohibited Uses</u>
          </b>
        </p>
        <div>
          You may use the Website only for lawful purposes and in accordance
          with these Terms of Use. You agree not to use the Website:
          <ul>
            <li>
              In any way that violates any applicable federal, state, local, or
              international law or regulation (including, without limitation,
              any laws regarding the export of data or software to and from the
              US or other countries).
            </li>
            <li>
              For the purpose of exploiting, harming, or attempting to exploit
              or harm minors in any way by exposing them to inappropriate
              content, asking for personally identifiable information, or
              otherwise.
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or
              promotional material without our prior written consent, including
              any “junk mail,” “chain letter,” “spam,” or any other similar
              solicitation.
            </li>
            <li>
              To impersonate or attempt to impersonate the Company, a Company
              employee, another user, or any other person or entity (including,
              without limitation, by using email addresses or screen names
              associated with any of the foregoing).
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone’s
              use or enjoyment of the Website, or which, as determined by us,
              may harm the Company or users of the Website, or expose them to
              liability.
            </li>
          </ul>
          Additionally, you agree not to:
          <ul>
            <li>
              Use the Website in any manner that could disable, overburden,
              damage, or impair the site or interfere with any other party’s use
              of the Website, including their ability to engage in real time
              activities through the Website.
            </li>
            <li>
              Use any robot, spider, or other automatic device, process, or
              means to access the Website for any purpose, including monitoring
              or copying any of the material on the Website.
            </li>
            <li>
              Use any manual process to monitor or copy any of the material on
              the Website, or for any other purpose not expressly authorized in
              these Terms of Use, without our prior written consent.
            </li>
            <li>
              Use any device, software, or routine that interferes with the
              proper working of the Website.
            </li>
            <li>
              Introduce any viruses, Trojan horses, worms, logic bombs, or other
              material that is malicious or technologically harmful.
            </li>
            <li>
              Attempt to gain unauthorized access to, interfere with, damage, or
              disrupt any parts of the Website, the server on which the Website
              is stored, or any server, computer, or database connected to the
              Website.
            </li>
            <li>
              Attack the Website via a denial-of-service attack or a distributed
              denial-of-service attack.
            </li>
            <li>
              Otherwise attempt to interfere with the proper working of the
              Website.
            </li>
          </ul>
        </div>
        <p className="my-2">
          <b>
            <u>Reliance on Information Posted</u>
          </b>
        </p>
        <p>
          The information presented on or through the Website is made available
          solely for general information purposes. We do not warrant the
          accuracy, completeness, or usefulness of this information. Any
          reliance You place on such information is strictly at Your own risk.
          We disclaim all liability and responsibility arising from any reliance
          placed on such materials by You or any other visitor to the Website,
          or by anyone who may be informed of any of its contents.
        </p>
        <p>
          This Website may include content provided by third parties, including
          materials provided by other users, and third-party licensors,
          syndicators, aggregators, and/or reporting services. All statements
          and/or opinions expressed in these materials, and all articles and
          responses to questions and other content, other than the content
          provided by the Company, are solely the opinions and the
          responsibility of the person or entity providing those materials.
          These materials do not necessarily reflect the opinion of the Company.
          We are not responsible, or liable to You or any third party, for the
          content or accuracy of any materials provided by any third parties.
        </p>
        <p className="my-2">
          <b>
            <u>Changes to the Website</u>
          </b>
        </p>
        <p>
          We may update the content on this Website from time to time, but its
          content is not necessarily complete or up-to-date. Any of the material
          on the Website may be out of date at any given time, and we are under
          no obligation to update such material.
        </p>
        <p className="my-2">
          <b>
            <u>Information About You and Your Visits to the Website</u>
          </b>
        </p>
        <p>
          All information we collect on this Website is subject to our Privacy
          Policy (accessible at{" "}
          <a href="https://tapnptailgate.app/privacy-policy">
            https://tapnptailgate.app/privacy-policy
          </a>
          ). By using the Website, You consent to all actions taken by us with
          respect to Your information in compliance with the Privacy Policy.
        </p>
        <p className="my-2">
          <b>
            <u>Linking to the Website</u>
          </b>
        </p>
        <p>
          You may link to our homepage, provided You do so in a way that is fair
          and legal and does not damage our reputation or take advantage of it,
          but You must not establish a link in such a way as to suggest any form
          of association, approval, or endorsement on our part without our
          express written consent.
        </p>
        <p className="my-2">
          <b>
            <u>Links from the Website</u>
          </b>
        </p>
        <p>
          If the Website contains links to other sites and resources provided by
          third parties, these links are provided for Your convenience only.
          This includes links contained in advertisements, including banner
          advertisements and sponsored links. We have no control over the
          contents of those sites or resources, and accept no responsibility for
          them or for any loss or damage that may arise from Your use of them.
          If You decide to access any of the third-party websites linked to this
          Website, You do so entirely at Your own risk and subject to the terms
          and conditions of use for such websites.
        </p>
        <p className="my-2">
          <b>
            <u>Geographic Restrictions</u>
          </b>
        </p>
        <p>
          The owner of the Website is based in the State of Wisconsin in the
          United States. We provide this Website for use only by persons located
          in the United States. We make no claims that the Website or any of its
          content is accessible or appropriate outside of the United States.
          Access to the Website may not be legal by certain persons or in
          certain countries. If You access the Website from outside the United
          States, You do so on Your own initiative and are responsible for
          compliance with local laws.
        </p>
        <p className="my-2">
          <b>
            <u>Disclaimer of Warranties</u>
          </b>
        </p>
        <p>
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the Website will
          be free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy Your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our site for
          any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY
          LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
          DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
          TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
          EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE
          TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH
          THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON
          ANY WEBSITE LINKED TO IT.
        </p>
        <p>
          YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
          CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
          PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY
          NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
          REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE.
          WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
          ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE,
          ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
          WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS
          WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE
          ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE
          OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE
          MEET YOUR NEEDS OR EXPECTATIONS.
        </p>
        <p>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
          FROM THE COMPANY OR THROUGH OR FROM THE SERVICES SHALL CREATE ANY
          WARRANTY NOT EXPRESSLY STATED IN THE TERMS AND CONDITIONS.
        </p>
        <p>
          TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS
          ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
          OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
          MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
        </p>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <p>
          <b>
            <u>Limitation on Liability </u>
          </b>
        </p>
        <p>
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY,
          ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
          AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND,
          UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE,
          OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY
          CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT,
          INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
          INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING,
          EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
          OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
          AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT,
          OR OTHERWISE, EVEN IF FORESEEABLE, EXCEED THE AMOUNT YOU HAVE PAID TO
          THE COMPANY FOR THE APPLICABLE SERVICE IN THE LAST TWELVE (12) MONTHS
          OUT OF WHICH LIABILITY AROSE.
        </p>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR
          LIMITED UNDER APPLICABLE LAW.
        </p>
        <p className="my-2">
          <b>
            <u>Personal Injury or Death</u>
          </b>
        </p>
        <p>
          You acknowledge that motor vehicle accidents can be dangerous and that
          collision with other motor vehicles or with other objects can cause
          injury or death to You or a third party. YOU EXPRESSLY UNDERSTAND AND
          AGREE THAT NEITHER THE COMPANY NOR ANY OF ITS SUBSIDIARIES, DIVISIONS,
          AFFILIATES, AGENTS, PARTNERS, LICENSEES, OR LICENSORS SHALL BE LIABLE
          TO YOU OR ANYONE ELSE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO,
          DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
          LOSSES (EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES), RESULTING FROM THE DESTRUCTION OR LOSS OF PROPERTY,
          PERSONAL INJURY OR DEATH RELATED IN ANY WAY TO THE USE OF THE
          SERVICES.
        </p>
        <p className="my-2">
          <b>
            <u>Indemnification</u>
          </b>
        </p>
        <p>
          You agree to defend, indemnify, and hold harmless the Company, its
          affiliates, licensors, and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors, and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses, or fees (including reasonable attorneys’ fees) arising out
          of or relating to (a) Your violation of these Terms of Use or Your use
          of the Website, including, but not limited to, any use of the
          Website’s content, services, and products other than as expressly
          authorized in these Terms of Use, or Your use of any information
          obtained from the Website; and (b) Your use or reliance on, or
          publication, communication or distribution of anything on or from the
          Website.
        </p>
        <p className="my-2">
          <b>
            <u>Governing Law and Jurisdiction</u>
          </b>
        </p>
        <p>
          All matters relating to the Website and these Terms of Use, and any
          dispute or claim arising therefrom or related thereto (in each case,
          including non-contractual disputes or claims), shall be governed by
          and construed in accordance with the internal laws of the State of
          Wisconsin without giving effect to any choice or conflict of law
          provision or rule (whether of the State of Wisconsin or any other
          jurisdiction).
        </p>
        <p>
          Any legal suit, action, or proceeding arising out of, or related to,
          these Terms of Use or the Website shall be instituted exclusively in
          the federal courts of the United States or the courts of the State of
          Wisconsin, in each case located in the City of Milwaukee and County of
          Milwaukee, although we retain the right to bring any suit, action, or
          proceeding against You for breach of these Terms of Use in Your
          country of residence or any other relevant country. You waive any and
          all objections to the exercise of jurisdiction over You by such courts
          and to venue in such courts.
        </p>
        <p className="my-2">
          <b>
            <u>
              Dispute Resolution by Binding Arbitration and Class Action Waiver
            </u>
          </b>
        </p>
        <p className="my-2">
          <b>
            <u>
              PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR LEGAL
              RIGHTS.{" "}
            </u>
          </b>
        </p>
        <p>
          This Dispute Resolution by Binding Arbitration and Class Action Waiver
          section is referred to in this Terms of Service as the “Arbitration
          Agreement.” You agree that any and all disputes or claims that have
          arisen or may arise between You and the Company, whether arising out
          of or relating to this Terms of Service (including any alleged breach
          thereof), the Services, any advertising, any aspect of the
          relationship or transactions between us, , at Company’s sole
          discretion, be resolved through final and binding arbitration, rather
          than a court, in accordance with the terms of this Arbitration
          Agreement, except that You may assert individual claims in small
          claims court, if Your claims qualify. Further, this Arbitration
          Agreement does not preclude You from exercising rights provided by
          federal, state, or local agencies, and such agencies can, if the law
          allows, seek relief against us on Your behalf. You agree that, by
          entering into these Terms of Service, You and the Company are each
          waiving the right to a trial by jury or to participate in a class
          action. Your rights will be determined by a neutral arbitrator, not a
          judge or jury. The Federal Arbitration Act governs the interpretation
          and enforcement of this Arbitration Agreement.
        </p>
        <p>
          <i>
            Prohibition of Class and Representative Actions and
            Non-Individualized Relief
          </i>
        </p>
        <p>
          YOU AND THE COMPANY AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE
          OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS
          MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING.
          UNLESS BOTH YOU AND THE COMPANY AGREE OTHERWISE, THE ARBITRATOR MAY
          NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S OR PARTY’S CLAIMS AND
          MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED,
          REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD
          RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY
          IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT
          NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL
          CLAIM(S), EXCEPT THAT YOU MAY PURSUE A CLAIM FOR AND THE ARBITRATOR
          MAY AWARD PUBLIC INJUNCTIVE RELIEF UNDER APPLICABLE LAW TO THE EXTENT
          REQUIRED FOR THE ENFORCEABILITY OF THIS PROVISION.
        </p>
        <p>
          <i>Arbitration Procedures.</i>
        </p>
        <p>
          Arbitration will be conducted by a neutral arbitrator in accordance
          with the American Arbitration Association’s (“AAA”) rules and
          procedures, including the AAA’s Consumer Arbitration Rules
          (collectively, the “AAA Rules”), as modified by this Arbitration
          Agreement. For information on the AAA, please visit its website,
          http://www.adr.org. Information about the AAA Rules and fees for
          consumer disputes can be found at the AAA’s consumer arbitration page,
          http://www.adr.org/consumer_arbitration. If there is any inconsistency
          between any term of the AAA Rules and any term of this Arbitration
          Agreement, the applicable terms of this Arbitration Agreement will
          control unless the arbitrator determines that the application of the
          inconsistent Arbitration Agreement terms would not result in a
          fundamentally fair arbitration. The arbitrator must also follow the
          provisions of these Terms of Service as a court would. All issues are
          for the arbitrator to decide, including, but not limited to, issues
          relating to the scope, enforceability, and arbitrability of this
          Arbitration Agreement. Although arbitration proceedings are usually
          simpler and more streamlined than trials and other judicial
          proceedings, the arbitrator can award the same damages and relief on
          an individual basis that a court can award to an individual under the
          Terms of Service and applicable law. Decisions by the arbitrator are
          enforceable in court and may be overturned by a court only for very
          limited reasons.
        </p>
        <p>
          Unless the Company and You agree otherwise, any arbitration hearings
          will take place in a reasonably convenient location for both parties
          with due consideration of their ability to travel and other pertinent
          circumstances. If the parties are unable to agree on a location, the
          determination shall be made by AAA. If Your claim is for $10,000 or
          less, The Company agrees that You may choose whether the arbitration
          will be conducted solely on the basis of documents submitted to the
          arbitrator, through a telephonic hearing, or by an in-person hearing
          as established by the AAA Rules. If Your claim exceeds $10,000, the
          right to a hearing will be determined by the AAA Rules. Regardless of
          the manner in which the arbitration is conducted, the arbitrator shall
          issue a reasoned written decision sufficient to explain the essential
          findings and conclusions on which the award is based.
        </p>
        <p>
          <i>Arbitration Costs.</i>
        </p>
        <p>
          Payment of all filing, administration, and arbitrator fees
          (collectively, the “Arbitration Fees”) will be governed by the AAA
          Rules, unless otherwise provided in this Arbitration Agreement. If the
          value of the relief sought is $25,000 or less, at Your request, the
          Company will pay all Arbitration Fees. If the value of relief sought
          is more than $25,000 and You are able to demonstrate to the arbitrator
          that You are economically unable to pay Your portion of the
          Arbitration Fees or if the arbitrator otherwise determines for any
          reason that You should not be required to pay Your portion of the
          Arbitration Fees, the Company will pay Your portion of such fees. In
          addition, if You demonstrate to the arbitrator that the costs of
          arbitration will be prohibitive as compared to the costs of
          litigation, the Company will pay as much of the Arbitration Fees as
          the arbitrator deems necessary to prevent the arbitration from being
          cost-prohibitive. Any payment of attorneys’ fees will be governed by
          the AAA Rules.
        </p>
        <p>
          <i>Severability.</i>
        </p>
        <p>
          If a court or the arbitrator decides that any term or provision of
          this Arbitration Agreement (other than the subsection (b) titled
          “Prohibition of Class and Representative Actions and
          Non-Individualized Relief” above) is invalid or unenforceable, the
          parties agree to replace such term or provision with a term or
          provision that is valid and enforceable and that comes closest to
          expressing the intention of the invalid or unenforceable term or
          provision, and this Arbitration Agreement shall be enforceable as so
          modified. If a court or the arbitrator decides that any of the
          provisions of subsection (b) above titled “Prohibition of Class and
          Representative Actions and Non-Individualized Relief” are invalid or
          unenforceable, then the entirety of this Arbitration Agreement shall
          be null and void, unless such provisions are deemed to be invalid or
          unenforceable solely with respect to claims for public injunctive
          relief. The remainder of the Terms of Service will continue to apply.
        </p>
        <p className="my-2">
          <b>
            <u>Limitation on Time to File Claims</u>
          </b>
        </p>
        <p>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1)
          YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF
          ACTION OR CLAIM IS PERMANENTLY BARRED.
        </p>
        <p className="my-2">
          <b>
            <u>Waiver and Severability</u>
          </b>
        </p>
        <p>
          No waiver by the Company of any term or condition set out in these
          Terms of Use shall be deemed a further or continuing waiver of such
          term or condition or a waiver of any other term or condition, and any
          failure of the Company to assert a right or provision under these
          Terms of Use shall not constitute a waiver of such right or provision.
        </p>
        <p>
          If any provision of these Terms of Use is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal, or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Use will continue in full force and effect.
        </p>
        <p className="my-2">
          <b>
            <u>Entire Agreement</u>
          </b>
        </p>
        <p>
          The Terms of Use and our Privacy Policy constitute the sole and entire
          agreement between You and, collectively, Interstate Parking, LLC and
          IPC Technology Group, LLC regarding the Website and supersede all
          prior and contemporaneous understandings, agreements, representations,
          and warranties, both written and oral, regarding the Website.
        </p>
        <p className="my-2">
          <b>
            <u>Your Comments and Concerns</u>
          </b>
        </p>
        <p>
          This website is operated by Interstate Parking, LLC and/or IPC
          Technology Group, LLC, 275 West Wisconsin Avenue, Suite 140 Milwaukee,
          Wisconsin 53203
        </p>
        <p>
          All other feedback, comments, requests for technical support, and
          other communications relating to the Website should be directed to:
          info@interstateparking.com.
        </p>
      </div>
    </PageLayout>
  );
};

export default TermsOfUse;
