import { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import PageLayout from "../../components/Layout/PageLayout";
import TextField from "../../components/Inputs/TextField";
import PathConstants from "../../routes/pathConstants";

import { useParkingPass } from "../../hooks/parking-pass";

import ArrowForward from "../../assets/Icons/ArrowForward";
import ArrowBack from "../../assets/Icons/ArrowBack";
import { postPrepaid } from "../../services/prepaid";
import Modal from "../../components/Layout/Modal";
import Alert from "../../assets/Icons/Alert";
import Phone from "../../assets/Icons/Phone";
import Receipt from "../../assets/Icons/Receipt";
import ErrorModal from "../../components/Layout/ErrorModal";
import { normalizeBarcode } from "../../utils/formatBarcode";
import cacheKeys from "../../constants/cache";
import BarcodeQRScanner from "../../components/Buttons/BarcodeQRScanner";

const PrepaidTicket = () => {
  const [passAlreadyPurchased, setPassAlreadyPurchased] = useState(false);
  const [prepaidIsLoading, setPrepaidIsLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: parkingPass } = useParkingPass();
  const contactInfo = queryClient.getQueryData(["contactInfo"]);
  const prepaid = queryClient.getQueryData(["prepaid"]);

  const qrCodeCache = window.sessionStorage.getItem(cacheKeys.qrCode);

  useEffect(() => {
    if (!qrCodeCache) {
      setIsRedirecting(true);
      window.location.replace(PathConstants.ERROR);
    }

    if (!contactInfo) {
      setIsRedirecting(true);
      window.location.replace(`${PathConstants.WELCOME}?qr=${qrCodeCache}`);
    }
  }, [qrCodeCache, contactInfo]);

  const mutation = useMutation({
    mutationFn: (date) => postPrepaid(parkingPass.passBarcode, false, "", date),
    onSuccess: (data) => {
      queryClient.setQueryData(["prepaid"], data);
      if (data?.validTicket) {
        navigate(PathConstants.CHECKOUT_COMPLETE);
      } else {
        if (data?.failReason === "rateLotMismatch") {
          navigate(PathConstants.LOT_MISMATCH_PREPAID);
        } else if (data?.failReason === "alreadyPaid") {
          setPassAlreadyPurchased(true);
        } else if (data.failReason) {
          setShowErrorModal(true);
        }
      }
    },
    onError: (error) => {
      console.error(error);
    },
    onSettled: () => {
      setPrepaidIsLoading(false);
    },
  });

  const handleOnClickReceipt = useCallback(() => {
    navigate(PathConstants.RECEIPT);
  }, [navigate]);

  const handleOnSubmitPass = useCallback(() => {
    const date = new Date();
    setPrepaidIsLoading(true);
    mutation.mutate(date);
  }, [mutation]);

  const handleOnClickBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleOnChangeBarcode = useCallback(
    (e) => {
      queryClient.setQueryData(["parkingPass"], {
        passBarcode: normalizeBarcode(e.target.value.toUpperCase()),
      });
    },
    [queryClient]
  );
  const handleOnClick = useCallback(
    (path) => {
      navigate(path);
    },
    [navigate]
  );

  const continueEnabled = useMemo(
    () => !!parkingPass?.passBarcode,
    [parkingPass?.passBarcode]
  );

  if (isRedirecting) {
    return null;
  }

  return (
    <PageLayout title="Parking Pass" withCancel isLoading={prepaidIsLoading}>
      <ErrorModal
        open={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        failReason={prepaid?.failReason}
      />
      <Modal
        open={passAlreadyPurchased}
        onClose={() => setPassAlreadyPurchased(false)}
      >
        <div>
          <span className="mx-auto">
            <Alert />
            <div>
              <div className="text-center mt-4 font-bold text-lg">
                Pass Already Purchased
              </div>
              <div className="text-center mt-4">
                A parking pass for this vehicle has already been purchased for
                today&apos;s event.
              </div>
              <div
                className="mt-4"
                style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}
              >
                <Divider />
              </div>
              <div>
                <div className="mt-4 flex">
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={() => handleOnClick()}
                    color="primary"
                    style={{
                      fontWeight: 700,
                      height: "2.5rem",
                      flexGrow: 1,
                      marginRight: "1rem",
                      width: "45%",
                    }}
                    startIcon={<Phone />}
                  >
                    Need Help?
                  </Button>
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={handleOnClickReceipt}
                    color="secondary"
                    style={{
                      fontWeight: 700,
                      flexGrow: 1,
                      height: "2.5rem",
                      width: "45%",
                    }}
                    disabled={false}
                    startIcon={<Receipt fill="var(--neutral-white-color)" />}
                  >
                    View Receipt
                  </Button>
                </div>
              </div>
            </div>
          </span>
        </div>
      </Modal>
      <div className="text-secondary leading-4 text-sm mx-2">
        Scan or enter the 12 digit number located under the QR or barcode on
        your ticket.
      </div>
      <div className="my-5">
        <BarcodeQRScanner
          onScanSuccess={(data) =>
            handleOnChangeBarcode({ target: { value: data } })
          }
          onScanFailure={() => console.log("Scan failed")}
        />
        <TextField
          value={parkingPass?.passBarcode || ""}
          onChange={handleOnChangeBarcode}
          label="Pass Number"
          placeholder="1234-12345678"
          // add margin top to the text field
          className="mt-4"
        />
      </div>
      <div className="text-left text-secondary leading-4 text-sm font-bold">
        By continuing you agree to our{" "}
        <Link
          to={PathConstants.TERMS_OF_USE}
          className="underline text-secondary font-bold"
        >
          Terms of Use
        </Link>{" "}
        and{" "}
        <Link
          to={PathConstants.PRIVACY_POLICY}
          className="underline text-secondary font-bold"
        >
          Privacy Policy
        </Link>
      </div>
      <div className="mt-4">
        <Button
          size="large"
          variant="contained"
          onClick={handleOnClickBack}
          color="secondary"
          style={{
            fontWeight: 700,
            width: "30%",
            height: "3rem",
            position: "inline-block",
            marginRight: "1rem",
          }}
          startIcon={<ArrowBack fill="var(--neutral-white-color)" />}
        >
          Back
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={handleOnSubmitPass}
          color="primary"
          style={{ fontWeight: 700, width: "65%", height: "3rem" }}
          endIcon={
            <ArrowForward
              fill={
                !continueEnabled
                  ? "rgba(0, 0, 0, 0.26)"
                  : "var(--secondary-color)"
              }
            />
          }
          disabled={!continueEnabled}
        >
          Agree & Continue
        </Button>
      </div>
    </PageLayout>
  );
};

export default PrepaidTicket;
