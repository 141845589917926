export const normalizeExpiration = (value) => {
  if (!value) {
    return value;
  }
  let numerals = value.replace(/[^\d]/g, "");

  // decorating input
  return numerals.length === 0
    ? ""
    : numerals.length <= 2
    ? `${numerals.substring(0, 2)}`
    : `${numerals.substring(0, 2)} / ${numerals.substring(2, 4)}`;
};
