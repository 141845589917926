import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import ArrowBack from "../../assets/Icons/ArrowBack";
import ArrowForward from "../../assets/Icons/ArrowForward";
import { Button } from "@mui/material";
import ErrorModal from "../../components/Layout/ErrorModal";
import PageLayout from "../../components/Layout/PageLayout";
import PathConstants from "../../routes/pathConstants";
import Spinner from "../../assets/Icons/Spinner";
import TextField from "../../components/Inputs/TextField";
import cacheKeys from "../../constants/cache";
import { postPay } from "../../services/pay";
import { postPromo } from "../../services/promo";
import { useCheckout } from "../../hooks/checkout";
import { useNavigate } from "react-router-dom";
import { useSelectedRate } from "../../hooks/rate-select";

const Checkout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [promoCode, setPromoCode] = useState("");
  const [promoIsLoading, setPromoIsLoading] = useState("");
  const [promoError, setPromoError] = useState(null);
  const [rapidCheckoutIsPosting, setRapidCheckoutIsPosting] = useState(false);

  const [isRedirecting, setIsRedirecting] = useState(false);

  const { data: selected } = useSelectedRate();
  const contactInfo = queryClient.getQueryData(["contactInfo"]);

  const qrCodeCache = window.sessionStorage.getItem(cacheKeys.qrCode);

  useEffect(() => {
    if (!qrCodeCache) {
      setIsRedirecting(true);
      window.location.replace(PathConstants.ERROR);
    }

    if (selected?.selectedRateId?.length < 1 || !contactInfo) {
      setIsRedirecting(true);
      // should be safe to check synchronously
      window.location.replace(`${PathConstants.WELCOME}?qr=${qrCodeCache}`);
    }
  }, [qrCodeCache, selected, contactInfo]);

  const {
    data,
    isFetching,
    error: checkoutError,
  } = useCheckout(selected.selectedRateId, () =>
    navigate(`${PathConstants.WELCOME}?qr=${qrCodeCache}`)
  );

  const mutation = useMutation({
    mutationFn: () => postPromo(promoCode),
    onSuccess: (promoData) => {
      if (!promoData.failReason) {
        promoData.paymentProcessor = data.paymentProcessor;
        queryClient.setQueryData(["checkout"], promoData);
      } else {
        setPromoError(promoData.failReason);
      }
    },
    onError: () => {
      setPromoError("unknownSystemError");
    },
    onSettled: () => {
      setPromoIsLoading(false);
    },
  });

  const rapidCheckout = async () => {
    const date = new Date();
    const terms = date.toISOString();

    const paymentObject = {
      elavonResponseData: null,
      termsAndConditionsOK: terms,
      sendTextReceipt: !contactInfo.showEmail,
      sendEmailReceipt: contactInfo.showEmail,
      phoneNumber: contactInfo.phone,
      email: contactInfo.email,
    };

    try {
      setRapidCheckoutIsPosting(true);
      const payRes = await postPay(paymentObject);
      queryClient.setQueryData(["pay"], payRes);
      navigate(PathConstants.CHECKOUT_COMPLETE);
    } catch (error) {
      setPromoError("unknownSystemError");
    } finally {
      setRapidCheckoutIsPosting(false);
    }
  };

  const handleSetPromoCode = (value) => {
    setPromoCode(value.toUpperCase());
  };

  const handleOnSubmitPromo = () => {
    if (promoCode) {
      setPromoIsLoading(true);
      mutation.mutate(promoCode);
    }
  };

  const handleCheckout = () => {
    if (data.transactionDetails.amountDue === 0) {
      rapidCheckout();
    } else {
      if (data.paymentProcessor && data.paymentProcessor === "Stripe")
        navigate(PathConstants.STRIPE_PAYMENT);
      else navigate(PathConstants.PAYMENT);
    }
  };

  const handleOnClickBack = () => {
    navigate(-1);
  };

  const { transactionDetails = {} } = data;

  //Do not render anything if we are redirecting
  return isRedirecting ? null : (
    <PageLayout
      title="Checkout"
      withCancel
      isLoading={isFetching || rapidCheckoutIsPosting}
    >
      <ErrorModal
        failReason={
          promoError ?? checkoutError?.message ?? "unknownSystemError"
        }
        open={!!promoError || !!checkoutError}
        onClose={() => {
          setPromoError(null);
          if (checkoutError) navigate(-1);
        }}
      />
      <div className="pt-4 border-t border-neutral-light mx-2 ">
        <div className="flex">
          <div className="flex-1 text-left width-50% font-bold">
            {transactionDetails?.rateName
              ? `${transactionDetails?.rateName}:`
              : "Lot:"}
          </div>
          <div className="flex-1 text-right font-bold">
            $
            {transactionDetails?.taxType === "Inclusive"
              ? (
                  transactionDetails?.amountDue +
                  transactionDetails?.computedDiscount
                )
                  .toFixed(2)
                  .replace(/\.00$/, "")
              : transactionDetails?.subtotal?.toFixed(2).replace(/\.00$/, "")}
          </div>
        </div>
        {transactionDetails?.convenienceFee > 0 && (
          <div className="flex mt-4">
            <div className="flex-1 text-left width-50% font-bold">
              Convenience Fee:
            </div>
            <div className="flex-1 text-right font-bold">
              $
              {transactionDetails?.convenienceFee
                ?.toFixed(2)
                .replace(/\.00$/, "")}
            </div>
          </div>
        )}
        {transactionDetails?.promoID && (
          <div className="flex mt-4">
            <div className="flex-1 text-left width-50% font-bold text-success">
              {transactionDetails?.promoCode} Discount Code:
            </div>
            <div className="flex-1 text-right font-bold text-success">
              -$
              {transactionDetails?.computedDiscount
                ?.toFixed(2)
                .replace(/\.00$/, "")}
            </div>
          </div>
        )}
        {transactionDetails?.taxType ===
        "Inclusive" ? null : transactionDetails?.tax ? (
          <div className="flex mt-4">
            <div className="flex-1 text-left width-50% font-bold">Taxes:</div>
            <div className="flex-1 text-right font-bold">
              ${transactionDetails?.tax?.toFixed(2).replace(/\.00$/, "")}
            </div>
          </div>
        ) : null}
        <div className="flex border-t border-neutral-light my-4 pt-4">
          <div className="flex-1 mr-4">
            <TextField
              value={promoCode}
              onChange={(e) => handleSetPromoCode(e.target.value)}
              label="Promo Code"
              endIcon={promoIsLoading ? <Spinner /> : <></>}
            ></TextField>
          </div>
          <div className="flex-2">
            <Button
              size="large"
              variant="contained"
              onClick={() => handleOnSubmitPromo()}
              color="secondary"
              style={{
                fontWeight: 700,
                marginTop: "1.75rem",
                height: "2.5rem",
                position: "inline-block",
                verticalAlign: "end",
              }}
              disabled={promoIsLoading || !!transactionDetails?.promoID}
            >
              {transactionDetails?.promoID ? "Applied" : "Apply"}
            </Button>
          </div>
        </div>
        <div>
          <div className="flex border-t border-neutral-light pt-4">
            <div className="flex-1 text-left width-50% font-bold text-lg">
              Due Today:
            </div>
            <div className="flex-1 text-right font-bold text-xl">
              {transactionDetails?.computedDiscount > 0 && (
                <span className="text-neutral-dark strike font-thin">
                  <s>
                    $
                    {(
                      transactionDetails?.amountDue +
                      transactionDetails?.computedDiscount
                    )
                      .toFixed(2)
                      .replace(/\.00$/, "")}
                  </s>
                </span>
              )}
              <span>
                {" "}
                $
                {transactionDetails?.amountDue?.toFixed(2).replace(/\.00$/, "")}
              </span>
            </div>
          </div>
          {transactionDetails?.taxType === "Inclusive" && (
            <div className="text-left text-xs">
              All applicable taxes are included.
            </div>
          )}
        </div>
        <div className="mt-4 pt-4 border-t border-neutral-light">
          <Button
            size="large"
            variant="contained"
            onClick={() => handleOnClickBack()}
            color="secondary"
            style={{
              fontWeight: 700,
              width: "30%",
              height: "3rem",
              position: "inline-block",
              marginRight: "1rem",
            }}
            startIcon={<ArrowBack fill="var(--neutral-white-color)" />}
          >
            Back
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={() => handleCheckout()}
            color="primary"
            style={{ fontWeight: 700, width: "64%", height: "3rem" }}
            endIcon={<ArrowForward />}
          >
            Checkout
          </Button>
        </div>
      </div>
    </PageLayout>
  );
};

export default Checkout;
