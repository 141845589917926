export const postElavonAppleRequest = async (url, request) => {
  const elavonUrl = import.meta.env.VITE_ELAVON_URL;

  const res = await fetch(
    `${elavonUrl}/hosted-payments/service/payment/hpe/${url}`,
    {
      method: "POST",
      body: JSON.stringify(request),
    }
  );
  return await res.json();
};
