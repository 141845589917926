import Checkout from "../pages/Checkout";
import CheckoutComplete from "../pages/CheckoutComplete";
import ContactInfo from "../pages/ContactInfo";
import Error from "../pages/Error";
import LotMismatch from "../pages/LotMismatch";
import LotMismatchPrepaid from "../pages/LotMismatchPrepaid";
import ParkingRates from "../pages/ParkingRates";
import PathConstants from "./pathConstants";
import Payment from "../pages/Payment";
import PaymentStripe from "../pages/PaymentStripe";
import PrepaidTicket from "../pages/PrepaidTicket";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Receipt from "../pages/Receipt";
import ReceiptLink from "../pages/ReceiptLink";
import RedirectToMarketingSite from "../components/Misc/RedirectToMarketingSite/RedirectToMarketingSite";
import SessionExpired from "../pages/SessionExpired";
import TermsOfUse from "../pages/TermsOfUse";
import Welcome from "../pages/Welcome";
import { createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RedirectToMarketingSite />,
  },
  { path: PathConstants.WELCOME, element: <Welcome /> },
  { path: PathConstants.CONTACT_INFO, element: <ContactInfo /> },
  { path: PathConstants.PARKING_RATES, element: <ParkingRates /> },
  { path: PathConstants.CHECKOUT, element: <Checkout /> },
  { path: PathConstants.CHECKOUT_COMPLETE, element: <CheckoutComplete /> },
  { path: PathConstants.PAYMENT, element: <Payment /> },
  { path: PathConstants.STRIPE_PAYMENT, element: <PaymentStripe /> },
  { path: PathConstants.TERMS_OF_USE, element: <TermsOfUse /> },
  { path: PathConstants.PRIVACY_POLICY, element: <PrivacyPolicy /> },
  { path: PathConstants.PREPAID_TICKET, element: <PrepaidTicket /> },
  { path: PathConstants.LOT_MISMATCH, element: <LotMismatch /> },
  { path: PathConstants.LOT_MISMATCH_PREPAID, element: <LotMismatchPrepaid /> },
  { path: PathConstants.RECEIPT, element: <Receipt /> },
  { path: PathConstants.RECEIPT_LINK, element: <ReceiptLink /> },
  { path: PathConstants.SESSION_EXPIRED, element: <SessionExpired /> },
  { path: "*", element: <Error /> },
]);

export default router;
