export const normalizeSecurityCode = (value) => {
  if (!value) {
    return value;
  }
  let numerals = value.replace(/[^\d]/g, "");

  // special cases for deletion
  if (value.length - numerals.length === 2) {
    numerals = numerals.slice(0, -1);
  }

  // decorating input
  return numerals.length === 0
    ? ""
    : numerals.length <= 4
    ? numerals
    : numerals.substring(0, 4);
};
