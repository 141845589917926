import { useEffect, useState } from "react";
import { Button, Divider } from "@mui/material";
import PageLayout from "../../components/Layout/PageLayout";
import ArrowForward from "../../assets/Icons/ArrowForward";
import { useNavigate } from "react-router-dom";
import { usePlate } from "../../hooks/plate";
import Modal from "../../components/Layout/Modal";
import Alert from "../../assets/Icons/Alert";
import PathConstants from "../../routes/pathConstants";
import cacheKeys from "../../constants/cache";

const LotMismatch = () => {
  const { data: plate } = usePlate();
  const [showWarning, setShowWarning] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const qrCodeCache = window.sessionStorage.getItem(cacheKeys.qrCode);

  useEffect(() => {
    if (!qrCodeCache) {
      setIsRedirecting(true);
      window.location.replace(PathConstants.ERROR);
    }

    if (!plate?.warningData) {
      setIsRedirecting(true);
      window.location.replace(`${PathConstants.WELCOME}?qr=${qrCodeCache}`);
    }
  }, [qrCodeCache, plate]);

  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  //Do not render anything if we are redirecting
  return isRedirecting ? null : (
    <PageLayout
      withCancel
      title={
        <>
          <span>Alert</span>
          <br />
          <span>Lot Mismatch Detected</span>
        </>
      }
    >
      <Modal
        open={showWarning}
        onClose={() => {
          setShowWarning(false);
        }}
      >
        <div>
          <span className="mx-auto">
            <Alert />
            <div>
              <div className="text-center mt-4 font-bold text-lg">Warning</div>
              <div className="text-center mt-4">
                Parking in an incorrect location or paying the incorrect rate
                will result in fines and/or the vehicle being towed, at the
                owner&apos;s expense.
              </div>
              <div
                className="mt-4"
                style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}
              >
                <Divider />
              </div>
              <div>
                <div className="mt-4 flex flex-wrap">
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={() => setShowWarning(false)}
                    color="secondary"
                    style={{
                      fontWeight: 700,
                      height: "2.5rem",
                      flexGrow: 1,
                      margin: ".25rem",
                      minWidth: "160px",
                    }}
                  >
                    Take Me Back!
                  </Button>
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={() => {
                      handleNavigate(PathConstants.PARKING_RATES);
                    }}
                    color="error"
                    style={{
                      fontWeight: 700,
                      flexGrow: 1,
                      margin: ".25rem",
                      height: "2.5rem",
                      minWidth: "160px",
                    }}
                    disabled={false}
                  >
                    Proceed Anyways
                  </Button>
                </div>
              </div>
            </div>
          </span>
        </div>
      </Modal>
      <div className="pt-2  mx-5">
        It looks like you&apos;re trying to checkout with a pass for the{" "}
        <span className="font-bold">{plate?.warningData?.qrLotName}</span>, but
        your vehicle appears to be located in the{" "}
        <span className="font-bold">{plate?.warningData?.cameraLotName}</span>.
      </div>
      <div className="pt-6 mx-5">
        If you are confident that you are in the correct Lot for your pass, you
        may still proceed with the checkout process.
      </div>
      <div className="mx-2 my-5">
        <Button
          size="large"
          variant="contained"
          color="error"
          onClick={() => setShowWarning(true)}
          style={{ fontWeight: 700, width: "100%" }}
          endIcon={<ArrowForward fill="var(--neutral-white-color)" />}
        >
          Proceed with Checkout
        </Button>
      </div>
      <div className="mx-2 my-5">
        <Button
          size="large"
          variant="contained"
          color="secondary"
          onClick={() =>
            handleNavigate(
              `${PathConstants.WELCOME}${
                qrCodeCache ? "?qr=" + qrCodeCache : ""
              }`
            )
          }
          style={{ fontWeight: 700, width: "100%" }}
        >
          Cancel Checkout
        </Button>
      </div>
    </PageLayout>
  );
};

export default LotMismatch;
