import React from "react";

const X = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8972 12.0654L18.7383 7.2243C19.2617 6.70093 19.2617 5.91589 18.7383 5.39252C18.215 4.86916 17.4299 4.86916 16.9065 5.39252L12.0654 10.1028L7.2243 5.39252C6.70093 4.86916 5.91589 4.86916 5.39252 5.39252C4.86916 5.91589 4.86916 6.70093 5.39252 7.2243L10.2336 12.0654L5.39252 16.7757C4.86916 17.2991 4.86916 18.0841 5.39252 18.6075C5.65421 18.8692 6.04673 19 6.30841 19C6.57009 19 6.96262 18.8692 7.2243 18.6075L12.0654 13.7664L16.7757 18.6075C17.0374 18.8692 17.4299 19 17.6916 19C17.9533 19 18.3458 18.8692 18.6075 18.6075C19.1308 18.0841 19.1308 17.2991 18.6075 16.7757L13.8972 12.0654Z"
        fill="#12284A"
      />
    </svg>
  );
};

export default X;
