import AmericanExpress from "../../../assets/Icons/AmericanExpress";
import Discover from "../../../assets/Icons/Discover";
import Mastercard from "../../../assets/Icons/Mastercard";
import Visa from "../../../assets/Icons/Visa";

const CreditCardImages = () => {
  return (
    <div
      className="flex flex-row"
      style={{
        position: "absolute",
        right: 0,
        top: ".15rem",
        justifyContent: "space-between",
        width: "5.5rem",
      }}
    >
      <Visa />
      <AmericanExpress />
      <Discover />
      <Mastercard />
    </div>
  );
};

export default CreditCardImages;
