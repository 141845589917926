import { queryOptions, useQuery } from "@tanstack/react-query";

export const getContactInfo = () => {
  return queryOptions({
    queryKey: ["rate-select"],
    initialData: {
      selectedRateId: "",
    },
  });
};

export const useSelectedRate = () => {
  return useQuery({
    ...getContactInfo(),
  });
};
