import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";

const validatePhoneNumber = (phoneNumber) => {
  try {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, "US");
    return (
      parsedNumber &&
      isValidPhoneNumber(phoneNumber, "US") &&
      (parsedNumber.getType() === "MOBILE" ||
        parsedNumber.getType() === "FIXED_LINE_OR_MOBILE")
    );
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default validatePhoneNumber;
