import React from "react";

const Accessibility = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6C11.45 6 10.9792 5.80417 10.5875 5.4125C10.1958 5.02083 10 4.55 10 4C10 3.45 10.1958 2.97917 10.5875 2.5875C10.9792 2.19583 11.45 2 12 2C12.55 2 13.0208 2.19583 13.4125 2.5875C13.8042 2.97917 14 3.45 14 4C14 4.55 13.8042 5.02083 13.4125 5.4125C13.0208 5.80417 12.55 6 12 6ZM17 22V17H12C11.45 17 10.9792 16.8042 10.5875 16.4125C10.1958 16.0208 10 15.55 10 15V9C10 8.45 10.1958 7.97917 10.5875 7.5875C10.9792 7.19583 11.45 7 12 7C12.4 7 12.7458 7.0875 13.0375 7.2625C13.3292 7.4375 13.6417 7.71667 13.975 8.1C14.8917 9.2 15.7208 9.95417 16.4625 10.3625C17.2042 10.7708 18.05 10.9833 19 11V13C18.1167 13 17.225 12.8083 16.325 12.425C15.425 12.0417 14.65 11.5833 14 11.05V14.5H17C17.55 14.5 18.0208 14.6958 18.4125 15.0875C18.8042 15.4792 19 15.95 19 16.5V22H17ZM10 22C8.61667 22 7.4375 21.5125 6.4625 20.5375C5.4875 19.5625 5 18.3833 5 17C5 15.8 5.37917 14.7417 6.1375 13.825C6.89583 12.9083 7.85 12.3333 9 12.1V14.15C8.41667 14.3833 7.9375 14.7542 7.5625 15.2625C7.1875 15.7708 7 16.35 7 17C7 17.8333 7.29167 18.5417 7.875 19.125C8.45833 19.7083 9.16667 20 10 20C10.65 20 11.2292 19.8125 11.7375 19.4375C12.2458 19.0625 12.6167 18.5833 12.85 18H14.9C14.6667 19.15 14.0917 20.1042 13.175 20.8625C12.2583 21.6208 11.2 22 10 22Z"
        fill="#12284A"
      />
    </svg>
  );
};

export default Accessibility;
