import React from "react";

const MobileIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4H16C16.5523 4 17 4.44772 17 5V19C17 19.5523 16.5523 20 16 20H8C7.44772 20 7 19.5523 7 19V5C7 4.44772 7.44771 4 8 4ZM5 5C5 3.34315 6.34315 2 8 2H16C17.6569 2 19 3.34315 19 5V19C19 20.6569 17.6569 22 16 22H8C6.34315 22 5 20.6569 5 19V5ZM9.99999 16.875C9.44771 16.875 8.99999 17.3227 8.99999 17.875C8.99999 18.4273 9.44771 18.875 9.99999 18.875H14C14.5523 18.875 15 18.4273 15 17.875C15 17.3227 14.5523 16.875 14 16.875H9.99999Z"
        fill="#12284A"
      />
    </svg>
  );
};

export default MobileIcon;
