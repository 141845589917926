import { RouterProvider } from "react-router-dom";

import Theme from "./Theme";
import { AppContextProvider } from "./context";
import router from "./routes/router";

import "./App.css";

function App() {
  return (
    <AppContextProvider>
      <Theme>
        <RouterProvider router={router} />
      </Theme>
    </AppContextProvider>
  );
}

export default App;
