import React from "react";

const AmericanExpress = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.499969 1.75C0.499969 0.783503 1.2733 0 2.22724 0H17.7727C18.7266 0 19.5 0.783502 19.5 1.75V12.25C19.5 13.2165 18.7266 14 17.7727 14H2.22724C1.2733 14 0.499969 13.2165 0.499969 12.25V1.75Z"
        fill="#1F72CD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.98167 4.94377L2.22724 9.05173H4.32754L4.58792 8.39675H5.18308L5.44345 9.05173H7.75529V8.55183L7.96129 9.05173H9.15716L9.36316 8.54127V9.05173H14.1712L14.7558 8.41376L15.3032 9.05173L17.7727 9.05702L16.0127 7.00922L17.7727 4.94377H15.3415L14.7724 5.56995L14.2422 4.94377H9.01175L8.5626 6.00407L8.10293 4.94377H6.00701V5.42666L5.77386 4.94377H3.98167ZM11.1614 5.52704H13.9224L14.7669 6.49218L15.6385 5.52704H16.483L15.1999 7.00858L16.483 8.47304H15.6002L14.7558 7.49669L13.8797 8.47304H11.1614V5.52704ZM11.8433 6.67556V6.13745L13.566 6.13694L14.3178 6.99751L13.5327 7.86278H11.8433V7.27532H13.3495V6.67556H11.8433ZM4.38801 5.52704H5.4118L6.57551 8.31267V5.52704H7.69703L8.59586 7.52433L9.42424 5.52704H10.5402V8.47477H9.86114L9.8556 6.16495L8.86567 8.47477H8.25827L7.2628 6.16495V8.47477H5.86593L5.60111 7.81393H4.17039L3.90611 8.4742H3.15768L4.38801 5.52704ZM4.41473 7.20309L4.88609 6.02585L5.35691 7.20309H4.41473Z"
        fill="#FEFFFF"
      />
    </svg>
  );
};

export default AmericanExpress;
