import { Button, Divider, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";

import ErrorModal from "../../components/Layout/ErrorModal";
import FlagUs from "../../assets/Icons/FlagUs";
import Mail from "../../assets/Icons/Mail";
import Message from "../../assets/Icons/Message";
import Modal from "../../components/Layout/Modal";
import PageLayout from "../../components/Layout/PageLayout";
import PathConstants from "../../routes/pathConstants";
import TextField from "../../components/Inputs/TextField";
import cacheKeys from "../../constants/cache";
import { normalizeLicensePlate } from "../../utils/formatLicensePlate";
import { normalizePhoneNumber } from "../../utils/formatPhone";
import { postEmail } from "../../services/email";
import { postPlateUpdate } from "../../services/plate";
import { postReceipt } from "../../services/receipt";
import { postText } from "../../services/text";
import { useQueryClient } from "@tanstack/react-query";
import { useReceipt } from "../../hooks/receipt";
import validateEmail from "../../utils/validate-email";
import validatePhoneNumber from "../../utils/validate-phone";

const Receipt = () => {
  const queryClient = useQueryClient();
  const TEXT_ERROR =
    "An error occurred sending your text receipt. Please try again or contact support if the problem persists.";
  const EMAIL_ERROR =
    "An error occurred sending your email receipt. Please try again or contact support if the problem persists.";
  const RECEIPT_ERROR =
    "An error occurred retrieving your receipt. Please try again or contact support if the problem persists.";

  const [sendPhoneReceipt, setSendPhoneReceipt] = useState(false);
  const [sendEmailReceipt, setSendEmailReceipt] = useState(false);
  const [fixLicensePlate, setFixLicensePlate] = useState(false);
  const [sendReceiptIsLoading, setSendReceiptIsLoading] = useState(false);
  const [plateUpdateIsLoading, setPlateUpdateIsLoading] = useState(false);
  const [receiptError, setReceiptError] = useState(null);
  const [plateError, setPlateError] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [emailWarning, setEmailWarning] = useState("");
  const [phoneWarning, setPhoneWarning] = useState("");
  const [newPlate, setNewPlate] = useState("");

  const [isRedirecting, setIsRedirecting] = useState(false);

  const { data: receiptData } = useReceipt();

  const qrCodeCache = window.sessionStorage.getItem(cacheKeys.qrCode);

  useEffect(() => {
    if (!qrCodeCache) {
      setIsRedirecting(true);
      window.location.replace(PathConstants.ERROR);
    }

    if (!receiptData?.receipt?.receiptID) {
      setIsRedirecting(true);
      window.location.replace(`${PathConstants.WELCOME}?qr=${qrCodeCache}`);
    }
  }, [qrCodeCache, receiptData]);

  useEffect(() => {
    if (!sendEmailReceipt || email === "") return;
    setEmailWarning(
      validateEmail(email) ? "" : "Please enter a valid email address."
    );
  }, [sendEmailReceipt, email]);

  useEffect(() => {
    if (!sendPhoneReceipt || phoneNumber === "") return;
    setPhoneWarning(
      validatePhoneNumber(phoneNumber)
        ? ""
        : "Please enter a valid mobile phone number."
    );
  }, [sendPhoneReceipt, phoneNumber]);

  const { receipt } = receiptData;

  const handleOnChangePhone = (e) => {
    setPhoneNumber(normalizePhoneNumber(e.target.value));
  };
  const handleOnChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSendReceipt = async () => {
    let op;

    try {
      if (sendPhoneReceipt) {
        setSendReceiptIsLoading(true);
        op = "TEXT";
        await postText(receipt?.receiptID, phoneNumber);
      }
      if (sendEmailReceipt) {
        setSendReceiptIsLoading(true);
        op = "EMAIL";
        await postEmail(receipt?.receiptID, email);
      }
    } catch (error) {
      setReceiptError(
        op === "TEXT"
          ? TEXT_ERROR
          : op === "EMAIL"
          ? EMAIL_ERROR
          : RECEIPT_ERROR
      );
      op = null;
    } finally {
      setSendReceiptIsLoading(false);
      setSendEmailReceipt(false);
      setSendPhoneReceipt(false);
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.toLocaleString("default", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    })}`;
  };

  const formatTime = (date) => {
    const d = new Date(date);
    return `${d.toLocaleString("default", {
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    })}`;
  };

  const goodToSend =
    (sendPhoneReceipt && validatePhoneNumber(phoneNumber)) ||
    (sendEmailReceipt && validateEmail(email));

  const handleOnChangePlate = (e) => {
    const changedPlate = e.target.value;
    const plate = changedPlate.substring(0, 18).toUpperCase();
    setNewPlate(normalizeLicensePlate(plate));
  };

  const handleOnChangePlateSubmit = async () => {
    setFixLicensePlate(false);
    setPlateUpdateIsLoading(true);
    try {
      const upadateRes = await postPlateUpdate(
        receipt.receiptID,
        newPlate,
        receipt.plate
      );
      const receiptRes = await postReceipt(upadateRes?.receipt?.receiptID);
      queryClient.setQueryData(["receipt"], receiptRes);
      receipt.plate = receiptRes?.receipt?.plate;
      receipt.lotName = receiptRes?.receipt?.lotName;
      receiptData.allowPlateSwitch = receiptRes.allowPlateSwitch;
      setPlateUpdateIsLoading(false);
    } catch (e) {
      setPlateUpdateIsLoading(false);
      setPlateError(e.message);
    }
  };

  //Do not render anything if we are redirecting
  return isRedirecting ? null : (
    <PageLayout
      title={formatDate(receipt?.startTime)}
      withCancel
      isLoading={sendReceiptIsLoading || plateUpdateIsLoading}
    >
      <ErrorModal
        description={receiptError}
        open={!!receiptError}
        onClose={() => {
          setReceiptError(null);
        }}
      />
      <ErrorModal
        failReason={plateError}
        open={!!plateError}
        onClose={() => {
          setPlateError(null);
        }}
      />
      <div className="text-secondary">
        <div className="divider">
          <div style={{ fontWeight: 700 }}></div>
        </div>
        <div className="text-left mt-4">
          <div className="font-bold">Event Details</div>

          <div>
            <div className="mt-1">
              <span>Location:</span>
              <span className="float-right">{receipt?.locationName}</span>
            </div>
            <div className="mt-1">
              <span>Date:</span>
              <span className="float-right">
                {formatDate(receipt?.startTime)}
              </span>
            </div>
          </div>
        </div>
        <div className="divider mt-4">
          <div style={{ fontWeight: 700 }}></div>
        </div>
        <div className="text-left mt-4">
          <div className="font-bold">Vehicle</div>
          <div>
            <div className="mt-1">
              <span>License Plate Number:</span>
              <span className="float-right">{receipt?.plate}</span>
            </div>
          </div>
          {receiptData?.allowPlateSwitch ? (
            <Button
              size="large"
              variant="contained"
              onClick={() => setFixLicensePlate(true)}
              color="secondary"
              style={{
                fontWeight: 700,
                width: "100%",
                height: "3rem",
                marginTop: "1rem",
              }}
            >
              Incorrect License Plate?
            </Button>
          ) : null}
        </div>
        <div className="divider mt-4">
          <div style={{ fontWeight: 700 }}></div>
        </div>
        <div className="text-left mt-4">
          <div className="font-bold">Event Details</div>
          <div>
            <div className="mt-1">
              <span>Lot:</span>
              <span className="float-right">{receipt?.lotName}</span>
            </div>
            <div className="mt-1">
              <span>Activation Time:</span>
              <span className="float-right">
                {formatTime(receipt?.startTime)}
              </span>
            </div>
            <div className="mt-1">
              <span>End Time:</span>
              <span className="float-right">
                {formatTime(receipt?.endTime)}
              </span>
            </div>
          </div>
        </div>
        <div className="divider mt-4">
          <div style={{ fontWeight: 700 }}></div>
        </div>
        <div className="text-left mt-4">
          <div className="font-bold">Payment Details</div>
          <div>
            <div className="mt-1">
              <span>Invoice Number:</span>
              <span className="float-right">{receipt?.invoiceID}</span>
            </div>
            <div className="mt-1">
              <span>Payment Method:</span>
              <span className="float-right">{receipt?.paymentDescription}</span>
            </div>
            <div className="mt-1">
              <span>Discount:</span>
              <span className="float-right">
                {typeof receipt?.discount === "number" &&
                !isNaN(receipt.discount) &&
                receipt.discount > 0
                  ? `-$${receipt.discount.toFixed(2).replace(/\.00$/, "")}`
                  : "--"}
              </span>
            </div>
            <div className="mt-1">
              <span>Total Paid:</span>
              <span className="float-right">
                $
                {typeof receipt?.amountPaid === "number" &&
                !isNaN(receipt.amountPaid)
                  ? receipt.amountPaid.toFixed(2).replace(/\.00$/, "")
                  : "0"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Button
        size="large"
        variant="contained"
        onClick={() => setSendPhoneReceipt(true)}
        color="secondary"
        style={{
          fontWeight: 700,
          width: "100%",
          height: "3rem",
          marginTop: "1rem",
        }}
        startIcon={<Message fill="var(--neutral-white-color)" />}
      >
        Send Receipt via Text
      </Button>
      <div className="mt-4">
        <Button
          size="large"
          variant="contained"
          onClick={() => setSendEmailReceipt(true)}
          color="secondary"
          style={{ fontWeight: 700, width: "100%", height: "3rem" }}
          startIcon={<Mail fill="var(--neutral-white-color)" />}
        >
          Send Receipt via Email
        </Button>
      </div>
      <Modal
        open={sendPhoneReceipt || sendEmailReceipt}
        withClose
        title={`Send receipt via ${sendPhoneReceipt ? "text" : "email"}`}
        onClose={() => {
          setSendPhoneReceipt(false);
          setSendEmailReceipt(false);
        }}
      >
        <div className="mt-8">
          <div style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}>
            <Divider />
          </div>
          {sendPhoneReceipt && (
            <div className="my-8">
              <TextField
                value={phoneNumber}
                onChange={(e) => handleOnChangePhone(e)}
                type="tel"
                label="Phone Number"
                placeholder="(123) 456-7890"
                error={phoneWarning}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FlagUs />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
          {sendEmailReceipt && (
            <div className="my-5">
              <TextField
                value={email}
                type="email"
                onChange={(e) => handleOnChangeEmail(e)}
                label="Email"
                placeholder="john.doe@example.com"
                error={emailWarning}
              />
            </div>
          )}
        </div>
        <div style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}>
          <Divider />
        </div>
        <div>
          <div className="mt-4 flex justify-end">
            <Button
              size="medium"
              variant="contained"
              onClick={() => handleSendReceipt()}
              color="primary"
              disabled={!goodToSend}
              style={{
                fontWeight: 700,
                height: "2.5rem",
                width: "45%",
              }}
            >
              Send Receipt
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={fixLicensePlate}
        withClose
        title="Update Plate"
        onClose={() => setFixLicensePlate(false)}
      >
        <div style={{ marginLeft: "-.5rem" }}>
          <div className="mt-8">
            <div className="mt-4">
              Did you checkout with the wrong license plate? No worries, you can
              still update it.
            </div>
            <div className="mt-4 justify-end">
              <TextField
                id="license-plate"
                value={newPlate}
                onChange={(e) => handleOnChangePlate(e)}
                label="License Plate"
                placeholder="ABC123"
              />
            </div>
            <div
              style={{
                marginTop: "1rem",
                marginLeft: "-1rem",
                marginRight: "-1.5rem",
              }}
            >
              <Divider />
            </div>
          </div>
          <div>
            <div className="mt-4 flex justify-end">
              <Button
                size="large"
                variant="contained"
                disabled={newPlate.length < 2}
                onClick={() => handleOnChangePlateSubmit()}
                color="primary"
                style={{
                  fontWeight: 700,
                  width: "100%",
                  height: "3rem",
                }}
              >
                Update License Plate
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </PageLayout>
  );
};

export default Receipt;
