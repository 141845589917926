import React from "react";

const CheckboxUnchecked = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="27"
        height="27"
        rx="5.5"
        fill="#EFEDE7"
        stroke="#C2C1BF"
      />
    </svg>
  );
};

export default CheckboxUnchecked;
