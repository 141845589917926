import React from "react";

const FlagUs = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_335_1351)">
        <path d="M2 4.99994H22V18.9999H2V4.99994Z" fill="#FEFFFF" />
        <path
          d="M2 4.99994H22V6.07655H2V4.99994ZM2 7.15316H22V8.22976H2V7.15316ZM2 9.30637H22V10.383H2V9.30637ZM2 11.4596H22V12.5362H2V11.4596ZM2 13.6169H22V14.6935H2V13.6169ZM2 15.7701H22V16.8467H2V15.7701ZM2 17.9233H22V18.9999H2V17.9233Z"
          fill="#D80027"
        />
        <path d="M2 4.99994H11.625V12.5362H2V4.99994Z" fill="#2E52B2" />
        <path
          d="M3.79392 10.6861L3.64383 10.1621L3.47872 10.6861H2.9834L3.38491 11.0013L3.23481 11.5253L3.64383 11.2019L4.04159 11.5253L3.88774 11.0013L4.29675 10.6861H3.79392ZM5.90655 10.6861L5.7527 10.1621L5.59509 10.6861H5.09977L5.50128 11.0013L5.35119 11.5253L5.7527 11.2019L6.15796 11.5253L6.00786 11.0013L6.40937 10.6861H5.90655ZM8.02667 10.6861L7.86532 10.1621L7.71522 10.6861H7.20864L7.62141 11.0013L7.46381 11.5253L7.86532 11.2019L8.27809 11.5253L8.12048 11.0013L8.522 10.6861H8.02667ZM10.1355 10.6861L9.98545 10.1621L9.82784 10.6861H9.32877L9.73403 11.0013L9.58393 11.5253L9.98545 11.2019L10.3907 11.5253L10.2294 11.0013L10.6421 10.6861H10.1355ZM5.7527 8.08258L5.59509 8.60655H5.09977L5.50128 8.92994L5.35119 9.44573L5.7527 9.12644L6.15796 9.44573L6.00786 8.92994L6.40937 8.60655H5.90655L5.7527 8.08258ZM3.64383 8.08258L3.47872 8.60655H2.9834L3.38491 8.92994L3.23481 9.44573L3.64383 9.12644L4.04159 9.44573L3.88774 8.92994L4.29675 8.60655H3.79392L3.64383 8.08258ZM7.86532 8.08258L7.71522 8.60655H7.20864L7.62141 8.92994L7.46381 9.44573L7.86532 9.12644L8.27809 9.44573L8.12048 8.92994L8.522 8.60655H8.02667L7.86532 8.08258ZM9.98545 8.08258L9.82784 8.60655H9.32877L9.73403 8.92994L9.58393 9.44573L9.98545 9.12644L10.3907 9.44573L10.2294 8.92994L10.6421 8.60655H10.1355L9.98545 8.08258ZM3.64383 6.01123L3.47872 6.52702H2.9834L3.38491 6.85041L3.23481 7.37029L3.64383 7.0469L4.04159 7.37029L3.88774 6.85041L4.29675 6.52702H3.79392L3.64383 6.01123ZM5.7527 6.01123L5.59509 6.52702H5.09977L5.50128 6.85041L5.35119 7.37029L5.7527 7.0469L6.15796 7.37029L6.00786 6.85041L6.40937 6.52702H5.90655L5.7527 6.01123ZM7.86532 6.01123L7.71522 6.52702H7.20864L7.62141 6.85041L7.46381 7.37029L7.86532 7.0469L8.27809 7.37029L8.12048 6.85041L8.522 6.52702H8.02667L7.86532 6.01123ZM9.98545 6.01123L9.82784 6.52702H9.32877L9.73403 6.85041L9.58393 7.37029L9.98545 7.0469L10.3907 7.37029L10.2294 6.85041L10.6421 6.52702H10.1355L9.98545 6.01123Z"
          fill="#FEFFFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_335_1351">
          <rect x="2" y="4.99994" width="20" height="14" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FlagUs;
