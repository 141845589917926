import { useEffect } from "react";

const RedirectToMarketingSite = () => {
  useEffect(() => {
    window.location.href = "https://www.inflowparking.com";
  }, []);

  return (
    <div
      style={{
        marginTop: "1rem",
      }}
    >
      If you are not redirected automatically, click{" "}
      <a
        href="https://www.inflowparking.com"
        aria-label="Go to InFlow Parking home page"
      >
        here
      </a>
      .
    </div>
  );
};

export default RedirectToMarketingSite;
