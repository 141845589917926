import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {
  const [theme, setTheme] = useState({});

  return (
    <AppContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.node,
};
