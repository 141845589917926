import { useEffect, useState } from "react";

import ElavonErrorModal from "../../components/Layout/ElavonErrorModal";
import { Elements } from "@stripe/react-stripe-js";
import ErrorModal from "../../components/Layout/ErrorModal";
import PageLayout from "../../components/Layout/PageLayout";
import PathConstants from "../../routes/pathConstants";
import Stripe from "./stripe";
import cacheKeys from "../../constants/cache";
import { loadStripe } from "../../utils/loadStripe";
import { useNavigate } from "react-router-dom";
import { usePaymentIntent } from "../../hooks/payment-intent";
import { useQueryClient } from "@tanstack/react-query";

const stripeKey = import.meta.env.VITE_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey);

const PaymentStripe = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [paymentIntentError, setPaymentIntentError] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [showPaymentError, setShowPaymentError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const data = queryClient.getQueryData(["checkout"]);
  const qrCodeCache = window.sessionStorage.getItem(cacheKeys.qrCode);

  //Check for existing state before rendering component
  useEffect(() => {
    if (!qrCodeCache) {
      setIsRedirecting(true);
      window.location.replace(PathConstants.ERROR);
    }

    if (!data) {
      setIsRedirecting(true);
      window.location.replace(`${PathConstants.WELCOME}?qr=${qrCodeCache}`);
    }
  }, [qrCodeCache, data]);

  const {
    data: paymentIntent,
    isFetching,
    error: pageLoadError,
  } = usePaymentIntent("Stripe", null, setPaymentIntentError);

  if (!data || !qrCodeCache || isRedirecting) {
    return null; // Early return to prevent rendering when data is undefined or redirecting
  }

  const setPaymentError = (errorMessage) => {
    setErrorMessage(errorMessage);
    setShowPaymentError(true);
  };

  //Do not render anything if we are redirecting
  return isRedirecting ? null : (
    <PageLayout
      title="Enter Payment Information"
      withCancel
      isLoading={isFetching}
    >
      <ErrorModal
        open={!!paymentIntentError || !!pageLoadError}
        onClose={() => {
          setPaymentIntentError(null);
          navigate(-1);
        }}
        failReason={paymentIntentError ?? "unknownSystemError"}
      />
      {paymentIntent &&
        paymentIntent.stripeRequestData &&
        paymentIntent.stripeRequestData.clientSecret && (
          <Elements
            options={{
              clientSecret: paymentIntent.stripeRequestData.clientSecret,
            }}
            stripe={stripePromise}
          >
            <Stripe
              setPaymentError={setPaymentError}
              setPaymentIntentError={setPaymentIntentError}
            />
          </Elements>
        )}
      <ElavonErrorModal
        open={!!showPaymentError}
        onClose={() => setShowPaymentError(false)}
        errorLabel={"Payment Failed"}
        errorDescription={errorMessage}
      ></ElavonErrorModal>
    </PageLayout>
  );
};

export default PaymentStripe;
