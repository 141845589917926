import React from "react";

const Worker = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 20V17.2C4 16.65 4.14167 16.1333 4.425 15.65C4.70833 15.1667 5.1 14.8 5.6 14.55C6.45 14.1167 7.40833 13.75 8.475 13.45C9.54167 13.15 10.7167 13 12 13C13.2833 13 14.4583 13.15 15.525 13.45C16.5917 13.75 17.55 14.1167 18.4 14.55C18.9 14.8 19.2917 15.1667 19.575 15.65C19.8583 16.1333 20 16.65 20 17.2V20H4ZM6 18H18V17.2C18 17.0167 17.9542 16.85 17.8625 16.7C17.7708 16.55 17.65 16.4333 17.5 16.35C16.9 16.05 16.1292 15.75 15.1875 15.45C14.2458 15.15 13.1833 15 12 15C10.8167 15 9.75417 15.15 8.8125 15.45C7.87083 15.75 7.1 16.05 6.5 16.35C6.35 16.4333 6.22917 16.55 6.1375 16.7C6.04583 16.85 6 17.0167 6 17.2V18ZM12 12C10.9 12 9.95833 11.6083 9.175 10.825C8.39167 10.0417 8 9.1 8 8H7.75C7.6 8 7.47917 7.95417 7.3875 7.8625C7.29583 7.77083 7.25 7.65 7.25 7.5C7.25 7.35 7.29583 7.22917 7.3875 7.1375C7.47917 7.04583 7.6 7 7.75 7H8C8 6.25 8.18333 5.575 8.55 4.975C8.91667 4.375 9.4 3.9 10 3.55V4.5C10 4.65 10.0458 4.77083 10.1375 4.8625C10.2292 4.95417 10.35 5 10.5 5C10.65 5 10.7708 4.95417 10.8625 4.8625C10.9542 4.77083 11 4.65 11 4.5V3.15C11.15 3.1 11.3083 3.0625 11.475 3.0375C11.6417 3.0125 11.8167 3 12 3C12.1833 3 12.3583 3.0125 12.525 3.0375C12.6917 3.0625 12.85 3.1 13 3.15V4.5C13 4.65 13.0458 4.77083 13.1375 4.8625C13.2292 4.95417 13.35 5 13.5 5C13.65 5 13.7708 4.95417 13.8625 4.8625C13.9542 4.77083 14 4.65 14 4.5V3.55C14.6 3.9 15.0833 4.375 15.45 4.975C15.8167 5.575 16 6.25 16 7H16.25C16.4 7 16.5208 7.04583 16.6125 7.1375C16.7042 7.22917 16.75 7.35 16.75 7.5C16.75 7.65 16.7042 7.77083 16.6125 7.8625C16.5208 7.95417 16.4 8 16.25 8H16C16 9.1 15.6083 10.0417 14.825 10.825C14.0417 11.6083 13.1 12 12 12ZM12 10C12.55 10 13.0208 9.80417 13.4125 9.4125C13.8042 9.02083 14 8.55 14 8H10C10 8.55 10.1958 9.02083 10.5875 9.4125C10.9792 9.80417 11.45 10 12 10Z"
        fill="#12284A"
      />
    </svg>
  );
};

export default Worker;
