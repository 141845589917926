export const normalizeBarcode = (value) => {
  if (!value) {
    return value;
  }
  let validCharacters = value.replace(/[^a-zA-Z0-9]/, "");

  // decorating input
  return validCharacters.length === 0
    ? ""
    : validCharacters.length <= 4
    ? `${validCharacters.substring(0, 4)}`
    : `${validCharacters.substring(0, 4)}-${validCharacters.substring(4, 12)}`;
};
